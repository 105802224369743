import { DeckGLProps } from '@deck.gl/react/typed';
import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import {
  Dictionary,
  clamp,
  compact,
  filter,
  find,
  forEach,
  fromPairs,
  isEmpty,
  keyBy,
  map,
  pickBy,
  reduce,
  reject,
  slice,
  times,
} from 'lodash';
import { useEffect, useMemo, useTransition } from 'react';
import { useDebouncedCallback, useThrottledCallback } from 'use-debounce';
import { JsonParam, useQueryParams } from 'use-query-params';

import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { ImagePyramid } from 'components/Procedure/useSlideImages';
import { calculateMagnificationFromZoom } from 'utils/slideTransformation';
import { MAX_VIEWERS } from '../constants';
import { calculateRegistrationAndAngles, getSlidesZoomRatio } from '../registration';
import { OrthographicMapViewState } from './OrthographicMapview';
import {
  applyRegistrationToOtherViewerState,
  areViewStatesEqual,
  deckGLViewerStates,
  getInitialViewState,
} from './slidesViewerState';

export const didInteractWithViewer = times(MAX_VIEWERS, () => signal<boolean>(false));

export const useDeckGLViewStates = ({
  slides,
  updateMagnificationFromZoom,
  viewSizes,
  slidesBaseImagePyramids,
  magnificationValue,
}: {
  slides: SlideWithChannelAndResults[];
  updateMagnificationFromZoom: any;
  viewSizes: Array<{ width: number; height: number }>;
  slidesBaseImagePyramids: Dictionary<ImagePyramid>;
  magnificationValue: number;
}) => {
  useSignals();

  const slideRegistrationFields = useMemo(
    () =>
      map(compact(slides), (slide) => {
        const registrationProps = keyBy(
          map(compact(slides), (otherSlide) => ({
            otherSlideId: otherSlide.id,
            ...calculateRegistrationAndAngles([slide, otherSlide]),
          })),
          'otherSlideId'
        );

        const baseImagePyramid = slidesBaseImagePyramids[slide.id];
        if (!baseImagePyramid) {
          return null;
        }

        return {
          id: slide.id,
          viewerIndex: slide.viewerIndex,
          sizeCols: slide.sizeCols,
          registrationProps,
          maxResolution: slide.maxResolution ?? 1,
          minLevel: baseImagePyramid?.layerSource?.minLevel,
          maxLevel: baseImagePyramid?.layerSource?.maxLevel,
          imageSize: baseImagePyramid?.layerSource?.getImageSize() || { width: slide.sizeCols, height: slide.sizeRows },
        };
      }),
    [slides, slidesBaseImagePyramids]
  );

  const [urlViewStates, setUrlViewStates] = useQueryParams(
    fromPairs(times(MAX_VIEWERS, (viewerIndex) => [`viewer${viewerIndex}ViewState`, JsonParam]))
  );

  const debouncedSetUrlViewStates = useDebouncedCallback(setUrlViewStates, 500);

  useEffect(() => {
    // Update the view state from the URL if there was no interaction with the viewer
    forEach(slideRegistrationFields, ({ id, viewerIndex }) => {
      const urlViewerSlideState = urlViewStates[`viewer${viewerIndex}ViewState`]?.[id];
      if (!didInteractWithViewer[viewerIndex].value && urlViewerSlideState) {
        const currentViewerState = deckGLViewerStates[viewerIndex].value;
        deckGLViewerStates[viewerIndex].value = {
          ...currentViewerState,
          [id]: {
            ...currentViewerState?.[id],
            ...urlViewerSlideState,
          },
        };
      }
    });
  }, [slideRegistrationFields, urlViewStates]);

  const [, startTransition] = useTransition();

  const syncViewStates = useThrottledCallback(
    ({
      leadingViewer,
      didBearingChange,
      didZoomChange,
    }: {
      leadingViewer?: number;
      didBearingChange?: boolean;
      didZoomChange?: boolean;
    } = {}) => {
      const changedViewerStates: Dictionary<OrthographicMapViewState>[] = [];

      forEach(slideRegistrationFields, (slide) => {
        if (!isNaN(leadingViewer) && leadingViewer !== slide.viewerIndex) {
          // Skip if not leading viewer
          return;
        }
        const currentViewerState =
          changedViewerStates?.[slide?.viewerIndex] || deckGLViewerStates[slide?.viewerIndex]?.value;
        const currentViewSlideState = currentViewerState?.[slide.id];

        const baseViewStateForCurrentSlide = getInitialViewState({
          imageSize: slide.imageSize,
          maxLevel: slide.maxLevel,
          minLevel: slide.minLevel,
          viewSize: viewSizes[slide.viewerIndex],
          magnificationValue,
          maxResolution: slide.maxResolution,
        });

        const otherSlides = reject(slideRegistrationFields, { viewerIndex: slide.viewerIndex });
        if (!isEmpty(otherSlides) && !isEmpty(currentViewSlideState)) {
          forEach(otherSlides, (otherSlide) => {
            const otherViewerStateValue =
              changedViewerStates?.[otherSlide?.viewerIndex] || deckGLViewerStates[otherSlide.viewerIndex]?.value;
            const otherViewerSlideState = otherViewerStateValue?.[otherSlide.id] || {};

            const registrationProps = find(slide.registrationProps, { otherSlideId: otherSlide.id });

            const otherViewerBaseViewState = getInitialViewState({
              imageSize: otherSlide?.imageSize,
              maxLevel: otherSlide?.maxLevel,
              minLevel: otherSlide?.minLevel,
              isRegisteredFromSlide: registrationProps?.registration?.registrationSlideId === otherSlide.id,
              registration: registrationProps?.registration,
              viewSize: viewSizes[otherSlide.viewerIndex],
              magnificationValue,
              maxResolution: slide.maxResolution,
            });
            const shouldApplyBearing =
              didBearingChange ||
              isNaN(otherViewerSlideState?.bearing) ||
              otherViewerSlideState?.bearing === otherViewerBaseViewState.bearing;
            const shouldApplyZoom =
              didZoomChange ||
              isNaN(otherViewerSlideState?.zoom as number) ||
              otherViewerSlideState?.zoom === otherViewerBaseViewState.zoom;

            const newSlideState = {
              ...(slide.id !== otherSlide.id
                ? applyRegistrationToOtherViewerState({
                    isRegisteredFromSlide: registrationProps?.registration?.registrationSlideId === otherSlide.id,
                    registration: registrationProps?.registration,
                    leadingSlideState: { ...baseViewStateForCurrentSlide, ...currentViewSlideState },
                    otherSlideState: { ...otherViewerBaseViewState, ...otherViewerSlideState },
                    zoomRatio: getSlidesZoomRatio(slide, otherSlide),
                    shouldApplyBearing,
                    shouldApplyZoom,
                  })
                : { ...currentViewSlideState }),
            };
            if (!areViewStatesEqual(otherViewerSlideState, newSlideState)) {
              changedViewerStates[otherSlide.viewerIndex] = {
                ...otherViewerStateValue,
                [otherSlide.id]: newSlideState,
              };
            }
          });
        } else {
          const newViewSlideState = { ...baseViewStateForCurrentSlide, ...(currentViewSlideState || {}) };
          if (!areViewStatesEqual(currentViewSlideState, newViewSlideState)) {
            changedViewerStates[slide.viewerIndex] = {
              ...currentViewerState,
              [slide.id]: newViewSlideState,
            };
          }
        }
      });

      forEach(changedViewerStates, (viewerState, viewerIndex) => {
        if (viewerState) {
          // Apply updates to the viewer if the state has changed
          deckGLViewerStates[viewerIndex].value = viewerState;
        }
      });

      const slidesToUpdateUrls = filter(
        slideRegistrationFields,
        ({ viewerIndex }) => didInteractWithViewer[viewerIndex].value
      );
      const viewerStatesToUpdate = fromPairs(
        map(slidesToUpdateUrls, ({ id, viewerIndex }) => [
          `viewer${viewerIndex}ViewState`,
          {
            [id]: pickBy(
              deckGLViewerStates[viewerIndex].value?.[id],
              (value, key) => key !== 'transitionInterpolator' && typeof value !== 'function'
            ),
          },
        ])
      );
      startTransition(() => debouncedSetUrlViewStates(viewerStatesToUpdate, 'replaceIn'));
    },
    1,
    { trailing: true }
  );

  useEffect(() => {
    // Registration props changed, updating viewer states, including bearing to update the other viewer's angle for registered slides
    syncViewStates({ didBearingChange: true });
  }, [
    //  Intentionally not exhaustive, we only want to resync when the registration props change or the view sizes change
    viewSizes,
    JSON.stringify(slideRegistrationFields),
  ]);

  const viewStateChangeHandlers: Dictionary<DeckGLProps['onViewStateChange']> = useMemo(
    () =>
      reduce(
        slideRegistrationFields,
        (acc, slide) => ({
          ...acc,
          [slide.viewerIndex]: ({ viewState: newViewState, interactionState }) => {
            if (
              !interactionState.isDragging &&
              !interactionState.isPanning &&
              !interactionState.isRotating &&
              !interactionState.isZooming
            ) {
              // Skip if the change is not due to interacting in one of the supported ways - i.e. changes made from outside the viewer for registration
              return;
            }

            if (didInteractWithViewer[slide.viewerIndex]) {
              didInteractWithViewer[slide.viewerIndex].value = true;
            }
            const deckGLViewerState = deckGLViewerStates[slide.viewerIndex];
            const previousViewerState = deckGLViewerState.value;
            const currentSlideViewState = previousViewerState?.[slide.id];
            const didBearingChange =
              isNaN(currentSlideViewState?.bearing) || currentSlideViewState?.bearing !== newViewState.bearing;

            const leadingViewerBaseViewState = getInitialViewState({
              imageSize: slide?.imageSize,
              maxLevel: slide?.maxLevel,
              minLevel: slide?.minLevel,
              viewSize: viewSizes[slide.viewerIndex],
              magnificationValue,
              maxResolution: slide.maxResolution,
            });
            const newSlideViewStateWithUpdates: OrthographicMapViewState = {
              ...leadingViewerBaseViewState,
              ...(currentSlideViewState || {}),
              ...newViewState,
            };

            const zoomBeforeClamp = newSlideViewStateWithUpdates.zoom;
            newSlideViewStateWithUpdates.zoom = clamp(
              zoomBeforeClamp as number,
              newSlideViewStateWithUpdates.minZoom,
              newSlideViewStateWithUpdates.maxZoom
            );

            const didZoomChange = previousViewerState?.zoom !== newSlideViewStateWithUpdates.zoom;

            // Avoid removing the slide from the view
            const imageSize = slide.imageSize;
            newSlideViewStateWithUpdates.target = [
              clamp(newSlideViewStateWithUpdates.target[0], 0, imageSize.width),
              clamp(newSlideViewStateWithUpdates.target[1], 0, imageSize.height),
              ...slice(newSlideViewStateWithUpdates.target, 2),
            ] as [number, number] | [number, number, number];

            if (areViewStatesEqual(currentSlideViewState, newSlideViewStateWithUpdates)) {
              // Skip if view state hasn't changed
              return;
            }
            deckGLViewerState.value = { ...previousViewerState, [slide.id]: newSlideViewStateWithUpdates };

            if (didZoomChange) {
              const newMagnificationValue = calculateMagnificationFromZoom(
                newSlideViewStateWithUpdates.zoom,
                slide.maxResolution
              );

              updateMagnificationFromZoom(newMagnificationValue);
            }

            syncViewStates({ leadingViewer: slide.viewerIndex, didBearingChange, didZoomChange });
          },
        }),
        {} as Dictionary<DeckGLProps['onViewStateChange']>
      ),
    //  Intentionally not exhaustive, as we want to update the handlers when the magnification changes
    [viewSizes, JSON.stringify(slideRegistrationFields), updateMagnificationFromZoom]
  );

  return { viewStateChangeHandlers, slideRegistrationFields };
};
