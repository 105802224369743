import { Tagger } from './tagger';

export interface TodoOption {
  name: string; // class name (e.g. necrosis)
  className?: string;
  color?: string; // hex color code (e.g. #e6194b)
  displayName?: string;
  positive?: boolean;
}

export interface TodoType {
  // eslint-disable-next-line camelcase
  todo_types_id: number; // primary key
  todo: string; // the name of the todo (e.g. Breast regions)
  type: string; // (e.g. annotation_marker)
  options: TodoOption[];
}

export interface TodoDefinition {
  todo: string; // the name of the todo (e.g. registration)
  type: string; // type of the todo (e.g. annotation_marker)
  options: TodoOption[];
  // eslint-disable-next-line camelcase
  todo_types_id?: number;
}

export type GeoJSONPoint = number[];
export type GeoJSONPolygon = GeoJSONPoint[];

export type Coordinates = GeoJSONPoint | GeoJSONPolygon[];

export interface Feature {
  type: string | number; // (e.g. Feature)
  geometry: {
    type: string; // GeoJSON geometry type (e.g. Polygon, Point, etc.)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    coordinates: any;
  };
  properties: FeatureProperties;
  tags?: FeatureProperties;
}

export interface FeatureProperties {
  id: string;
  hidden: boolean;
  selected: boolean;
  diagnosis: string;
  markerType: string;
  shapeSubType: string;
}

export interface TodoDatum {
  todo: string; // the name of the todo (e.g. registration)
  markers: Feature[];
}

export interface AnnotationAssignment {
  annotationAssignmentId: number;
  name: string;
  todos: TodoDefinition[];
  description: string;
  allowedTaggerIds?: number[];
  archived: boolean;
  assignmentPriority: number;
  createdAt: Date;
  createdBy: string;
  taggerId?: number;
  slideIds?: string[];
  annotations?: Annotation[];
}

export enum AnnotationWorkingStates {
  Annotating = 'annotating',
  QA = 'qa',
  Done = 'done',
}

export interface Annotation {
  annotationId: number;
  slideId: string;
  annotationAssignment: AnnotationAssignment;
  createdAt: string;
  annotationsData?: TodoDatum[];
  // this is calculation happening on the back end, to not fetch all annotationsData
  annotationSummary?: { [key: string]: number }; // key: class, value: count of annotations
  taggerId: number;
  tagger?: Tagger;
  validFrom: string;
  validTo: string;
  workingState: AnnotationWorkingStates;
  participantTaggerId: number;
}

export enum AnnotationExtensionStates {
  Annotating = 'annotating',
  QA = 'qa',
  Done = 'done',
  None = 'none',
  AllAssignment = 'all',
  StarredAssignment = 'starred',
}

export interface AssignmentExtension {
  annotationAssignmentId: number;
  annotationExtensionsId: number;
  slideId: string;
  taggerId: number;
  workingState: AnnotationExtensionStates;
  extensionPriority: number;
  url: string;
}

export function generateAnnotationExtensionSlideAssignmentId(assignmentId: number, slideId: string) {
  return `${slideId}||${assignmentId}`;
}
