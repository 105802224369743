import { formTypes } from './reviewForm';

export enum Permission {
  /*
   * See README.md for instructions on how to sync these permission options with the DB.
   */
  AccessAnalysisStudio = 'access_analysis_studio',
  AddAndEditClinicalDataToAnyStudies = 'add_and_edit_clinical_data_to_any_studies',
  AddAndEditClinicalDataToOwnStudies = 'add_and_edit_clinical_data_to_own_studies',
  AddAndEditMetadataToAnyStudies = 'add_and_edit_metadata_to_any_studies',
  AddAndEditMetadataToOwnStudies = 'add_and_edit_metadata_to_own_studies',
  AnnotateSlides = 'annotate_slides',
  ApplyFiltersAndSaveQueriesAsCohorts = 'apply_filters_and_save_queries_as_cohorts',
  ApplyPermissionsAcrossLabs = 'apply_permissions_across_labs',
  AppointAdminsPerLab = 'appoint_admins_per_lab',
  ApproveCases = 'approve_cases',
  CanUseBetaViewer = 'can_use_beta_viewer',
  CanToggleAllMultiplexChannels = 'can_toggle_all_multiplex_channels',
  CreateStudies = 'create_studies',
  DefineRolesToLabUsers = 'define_roles_to_lab_users',
  DownloadCalculatedFeatures = 'download_calculated_features',
  EditAndDeleteAnyCohort = 'edit_and_delete_any_cohort',
  EditAndDeleteOwnCohorts = 'edit_and_delete_own_cohorts',
  EditExternalLabelOptions = 'edit_external_label_options',
  EditSlideTagsAssignments = 'edit_slide_tags_assignments',
  EditStudyNameAndDescription = 'basic_edit_study',
  EditSlideTagOptions = 'edit_slide_tag_options',
  EditStainTypeOptions = 'edit_stain_type_options',
  EditAreaTypeOptions = 'edit_area_type_options',
  EditClusterTypeOptions = 'edit_cluster_type_options',
  EditModelTypeOptions = 'edit_model_type_options',
  InitiateAnalysisInAnyStudy = 'initiate_analysis_in_any_study',
  InitiateAnalysisInTheirOwnStudies = 'initiate_analysis_in_their_own_studies',
  LimitAnyStudiesAccess = 'limit_any_studies_access',
  LimitOwnStudiesAccess = 'limit_own_studies_access',
  ManageNucleaiUsers = 'manage_nucleai_users',
  PublishResults = 'publish_results',
  ScoreHeatmaps = 'score_heatmaps',
  SeeOrchestrationId = 'see_orchestration_id',
  UploadSlidesToAnyStudies = 'upload_slides_to_any_studies',
  UploadSlidesToOwnStudies = 'upload_slides_to_own_studies',
  UseNucleaiExperimentalFeatures = 'use_nucleai_experimental_features',
  ViewAccessionDashboard = 'view_accession_dashboard',
  ViewAnnotations = 'view_annotations',
  ViewAnnotationsList = 'view_annotations_list',
  ViewClinicalData = 'view_clinical_data',
  ViewHeatmapScores = 'view_heatmap_scores',
  ViewMetadata = 'view_metadata',
  ViewResultsInViewer = 'view_results_in_viewer',
  ViewRoleAllocationPerUser = 'view_role_allocation_per_user',
  ViewSlides = 'view_slides',
  ViewStudies = 'view_studies',
  ViewJobs = 'view_jobs',
  RunInference = 'run_inference',
  ViewUnpublishedResults = 'view_unpublished_results',
  UpdateHighlightedFeatures = 'update_highlighted_features',
  EditHiddenFeatures = 'edit_hidden_features',
  CanDownloadFeatureNamesCsv = 'can_download_feature_names_csv',
  DownloadCellsTable = 'download_cells_table',
  EditOtherUsersOverviewPreset = 'edit_other_users_overview_preset',
  DeleteOtherUsersOverviewPreset = 'delete_other_users_overview_preset',
  EditStudyDefaultOverviewPreset = 'edit_study_default_overview_preset',
  ViewCasesTable = 'view_cases_table',
  ViewSlideThumbnailsNavigation = 'view_slide_thumbnails_navigation',
  ViewStudySettings = 'view_study_settings',
  PrepareApprovedCustomerResultsForDownload = 'prepare_approved_customer_results_for_download',
  EditQcQaLabels = 'edit_qc_labels',
  ViewInternalSlideLabels = 'view_internal_slide_labels',
  EditInferredFeatures = 'edit_inferred_features',
  RunCalculateFeatures = 'run_calculate_features',
  RunMultiplexCellSegmentation = 'run_multiplex_cell_segmentation',
  RunSecondaryAnalysis = 'run_secondary_analysis',
  ExecuteMultiplexBinaryClassifier = 'execute_multiplex_binary_classifier',
  ExecuteMultiplexNormalization = 'execute_multiplex_normalization',
  ExecuteMultiplexHistogram = 'execute_multiplex_histogram',
  ExecuteMultiplexThreshold = 'execute_multiplex_threshold',
  ExecuteMultiplexCellTyping = 'execute_multiplex_cell_typing',
  EditPlatformSettings = 'edit_platform_settings',
  ViewPendingSlides = 'view_pending_slides',
  ApplyCaseManifest = 'apply_case_manifest',
  ViewReviewMenu = 'view_review_menu',
  EditTaxonomyOptions = 'edit_taxonomy_options',
  EditCellRules = 'edit_cell_rules',

  ViewFormResponses = 'view_form_responses',
  FillForm = 'fill_form',

  ContinueJob = 'continue_job',
  CancelOwnJobs = 'cancel_own_jobs',
  CancelAllUsersJobs = 'cancel_all_users_jobs',

  CanArchiveViewRestoreOwnResults = 'can_archive_view_restore_own_results',
  CanViewRestoreResultsArchivedByAny = 'can_view_restore_results_archived_by_any',
}

export const permissionSubOptions = {
  [Permission.ViewFormResponses]: formTypes,
  [Permission.FillForm]: formTypes,
} as const;

export type PermissionSubOptions = typeof permissionSubOptions;

export interface PermissionOption {
  permission: Permission;
  description: string;
}

export const permissionToName = (permission?: PermissionOption, id?: Permission): string =>
  permission ? permission?.description || id : 'Deprecated permission';
