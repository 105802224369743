import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { filter, find, forEach, includes, isEmpty, lowerCase, map } from 'lodash';
import React, { useMemo } from 'react';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { defaultLayerColors } from 'components/theme/theme';
import { GroupedLayersVisualControls } from '../../../GroupedLayersVisualControls';
import { defaultHeatmapOpacity } from '../../../slidesVisualizationAndConfiguration';
import { useUpdatePmtHeatmapsSettingsOnChange } from './useUpdatePmtHeatmapsSettingsOnChange';

export const getPmtLayerId = (pmtHeatmap: FeatureMetadata, layerName: string) => `${pmtHeatmap.id}-${layerName}`;

export const computeDefaultPmtLayerSettings = (pmtHeatmap: FeatureMetadata, layerIndex: number, layerName?: string) => {
  const optionFromHeatmap = find(pmtHeatmap?.nestedItems, { key: layerName });
  return {
    id: getPmtLayerId(pmtHeatmap, layerName),
    color: optionFromHeatmap?.color || defaultLayerColors[+layerIndex % defaultLayerColors.length],
    opacity: defaultHeatmapOpacity,
    show: false,
    select: false,
  };
};

const defaultHeader = (
  <Grid container alignItems="center" justifyContent="space-between">
    <Grid item md={12}>
      <Typography variant={'h4'}>Protomap Tiles (Beta)</Typography>
    </Grid>
  </Grid>
);

export const ProtomapTree: React.FC<{
  pmtHeatmaps: FeatureMetadata[];
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  filterText: string;
  hideOrchestrationId?: boolean;
  onEmptyFilter?: () => void;
}> = ({ pmtHeatmaps, slideId, viewerIndex, filterText, hideOrchestrationId, stainTypeId, onEmptyFilter }) => {
  useSignals();

  const layerIdsToDisplayNames = useMemo(() => {
    const res: { [key: string]: string } = {};

    forEach(pmtHeatmaps, (pmtHeatmap) => {
      res[pmtHeatmap.id] = pmtHeatmap?.displayName;
      forEach(pmtHeatmap?.nestedItems, (layer) => {
        if (layer) {
          res[getPmtLayerId(pmtHeatmap, layer.key)] = layer.displayName || layer.key || layer.id;
        }
      });
    });
    return res;
  }, [pmtHeatmaps]);

  useUpdatePmtHeatmapsSettingsOnChange({
    slideId,
    viewerIndex,
    stainTypeId,
    pmtHeatmaps,
  });

  const groupedFilterLayers = useMemo(() => {
    const res: { [key: string]: string[] } = {};

    forEach(pmtHeatmaps, (pmtHeatmap) => {
      const pmtLayers = pmtHeatmap?.nestedItems;

      const filteredLayers =
        filterText !== ''
          ? filter(pmtLayers, (pmtLayerHeatmap) => {
              if (filterText === '') return true;

              const lowerCaseFilter = lowerCase(filterText);
              return (
                includes(lowerCase(pmtLayerHeatmap?.key), lowerCaseFilter) ||
                includes(lowerCase(pmtLayerHeatmap?.displayName), lowerCaseFilter)
              );
            })
          : pmtLayers;
      res[pmtHeatmap.id] = map(filteredLayers, 'key');
    });
    if (isEmpty(res)) {
      onEmptyFilter?.();
    }
    return res;
  }, [pmtHeatmaps, filterText]);

  const groupDisplayNames = useMemo(() => {
    const res: { [key: string]: string } = {};

    forEach(pmtHeatmaps, (pmtHeatmap) => {
      res[pmtHeatmap.id] = pmtHeatmap?.displayName || pmtHeatmap?.key;
    });
    return res;
  }, [pmtHeatmaps]);

  const groupOrchestrationIds = useMemo(() => {
    const res: { [key: string]: string } = {};

    forEach(pmtHeatmaps, (pmtHeatmap) => {
      res[pmtHeatmap.id] = pmtHeatmap?.orchestrationId;
    });
    return res;
  }, [pmtHeatmaps]);

  return (
    !isEmpty(groupedFilterLayers) && (
      <GroupedLayersVisualControls
        viewerIndex={viewerIndex}
        slideId={slideId}
        groupedLayers={groupedFilterLayers}
        groupOrchestrationIds={groupOrchestrationIds}
        groupDisplayNames={groupDisplayNames}
        layerIdsToDisplayNames={layerIdsToDisplayNames}
        stainTypeId={stainTypeId}
        hideOrchestrationId={hideOrchestrationId}
      />
    )
  );
};

// TODO: we can remove this if / when we deprecate the pmt demo
export const ProtomapTileControl: React.FC<{
  header?: React.ReactNode;
  pmtHeatmaps: FeatureMetadata[];
  slideId: string;
  viewerIndex: number;
  filterText: string;
  expandByDefault?: boolean;
  hideOrchestrationId?: boolean;
  stainTypeId?: string;
}> = ({ header, pmtHeatmaps, slideId, viewerIndex, filterText, expandByDefault, hideOrchestrationId, stainTypeId }) => {
  useSignals();
  const [expandAccordion, setExpandAccordion] = React.useState(Boolean(expandByDefault));

  const heatmapTree = (
    <ProtomapTree
      pmtHeatmaps={pmtHeatmaps}
      slideId={slideId}
      viewerIndex={viewerIndex}
      filterText={filterText}
      hideOrchestrationId={hideOrchestrationId}
      stainTypeId={stainTypeId}
    />
  );

  return (
    heatmapTree && (
      <Accordion square expanded={expandAccordion} onChange={() => setExpandAccordion(!expandAccordion)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{header || defaultHeader}</AccordionSummary>
        <AccordionDetails sx={{ padding: 1 }}>{heatmapTree}</AccordionDetails>
      </Accordion>
    )
  );
};
