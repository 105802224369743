import { COORDINATE_SYSTEM, Color } from '@deck.gl/core/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import { useSignals } from '@preact/signals-react/runtime';
import { first, last, map, slice, some, times } from 'lodash';
import { useEffect, useMemo, useState, useTransition } from 'react';

import {
  baseSlidesVisualSettings,
  defaultBaseSlideVisualSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { slidesChannelNormalizationSettings } from 'components/Procedure/SlideControls/Multiplex/channelNormalizations';
import {
  slidesChannelColorSettings,
  slidesChannelToggles,
} from 'components/Procedure/SlideControls/Multiplex/colorSettings';
import { MAX_CHANNELS } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/constants';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { ImagePyramid } from 'components/Procedure/useSlideImages';
import { defaultLayerColors } from 'components/theme/theme';
import { MAX_UINT16, MAX_UINT8 } from 'utils/constants';
import { hexToRgb } from 'utils/helpers';
import MultiScaleImageLayer, { OVERVIEW_LAYER_ID } from './StainsLayers/layers/multiScaleImageLayer';
import { isInterleaved } from './StainsLayers/layers/utils';

const defaultTransparentColor: Color = [0, 0, 0];

export const useBaseSlideLayers = ({
  slide,
  baseImagePyramids,
  overview,
}: {
  slide: SlideWithChannelAndResults;
  baseImagePyramids: ImagePyramid;
  overview?: boolean;
}): DeckGLProps['layers'] => {
  useSignals();
  const defaultContrastLimits: [min: number, max: number] = [0, slide.encoding === 'uint16' ? MAX_UINT16 : MAX_UINT8];

  const channelSource = baseImagePyramids?.layerSource;

  const viewerBaseSlideSettings = baseSlidesVisualSettings[slide.viewerIndex];
  const viewerChannelColorSettings = slidesChannelColorSettings[slide.viewerIndex]?.value;
  const viewerChannelNormalizationSettings = slidesChannelNormalizationSettings[slide.viewerIndex]?.value;
  const viewerChannelToggles = slidesChannelToggles[slide.viewerIndex];
  const slideBaseSettings = {
    ...defaultBaseSlideVisualSettings,
    ...(viewerBaseSlideSettings?.value?.[slide.id] || {}),
  };

  const slideChannelToggles = viewerChannelToggles?.value?.[slide?.id];

  const slideChannelsOrder = slice(baseImagePyramids?.layersOrder || [], 0, MAX_CHANNELS);
  const slideHasChannels = Boolean(slideChannelsOrder.length > 1);

  const orderedSlideChannelToggles = slideHasChannels
    ? map(slideChannelsOrder, (channelId) => slideChannelToggles?.[channelId])
    : undefined;

  const orderedSlideChannelColorSettings = slideHasChannels
    ? map(slideChannelsOrder, (channelId) => viewerChannelColorSettings?.[slide?.id]?.[channelId]?.value)
    : undefined;

  const orderedSlideChannelNormalizationSettings = slideHasChannels
    ? map(slideChannelsOrder, (channelId) => viewerChannelNormalizationSettings?.[slide?.id]?.[channelId]?.value)
    : undefined;

  const slideChannelColors: Color[] = slideHasChannels
    ? map(orderedSlideChannelColorSettings, (channelSettings): Color => {
        const channelColor = channelSettings?.color;
        if (!channelColor) {
          return defaultTransparentColor;
        }
        return channelColor?.rgb
          ? [channelColor?.rgb.r, channelColor?.rgb.g, channelColor?.rgb.b]
          : hexToRgb(channelColor);
      })
    : ([hexToRgb(first(defaultLayerColors))] as Color[]);

  const slideChannelsGamma: number[] = slideHasChannels
    ? map(orderedSlideChannelColorSettings, (channelSettings) => channelSettings?.gamma ?? 1)
    : [1];

  const slideContrastLimits = slideHasChannels
    ? map(orderedSlideChannelNormalizationSettings, (channelSettings): [min: number, max: number] => {
        const range = channelSettings ?? defaultContrastLimits;
        return channelSource?.meta?.isPng
          ? range
          : // Normalize range to 0-255
            (map(range, (value) => (value / last(defaultContrastLimits)) * 255) as [number, number]);
      })
    : [defaultContrastLimits];

  const slideChannelsVisible = slideHasChannels ? orderedSlideChannelToggles : [slideBaseSettings?.show];

  const channelOpacities = slideHasChannels
    ? map(orderedSlideChannelColorSettings, (channelSettings) => channelSettings?.opacity ?? 0)
    : [slideBaseSettings?.opacity ?? 0];

  // Memoize selections and visibility to avoid unnecessary layer data updates
  const selections = useMemo(
    // If no channels, use a single selection
    () => times(slideChannelsOrder?.length || 1, (layerIndex) => ({ layerIndex })),
    [slideChannelsOrder?.length]
  );

  const stableSlideChannelsVisible = useMemo(() => slideChannelsVisible, [JSON.stringify(slideChannelsVisible)]);

  const stableSlideContrastLimits = useMemo(() => slideContrastLimits, [JSON.stringify(slideContrastLimits)]);

  const stableSlideChannelOpacities = useMemo(() => channelOpacities, [JSON.stringify(channelOpacities)]);

  const stableSlideChannelColors = useMemo(() => slideChannelColors, [JSON.stringify(slideChannelColors)]);

  const stableSlideChannelsGamma = useMemo(() => slideChannelsGamma, [JSON.stringify(slideChannelsGamma)]);

  const [baseLayer, setBaseLayer] = useState<any>(null);
  const [, startTransition] = useTransition();

  const interleavedSource = isInterleaved(channelSource.shape);

  useEffect(() => {
    startTransition(() => {
      setBaseLayer(() =>
        channelSource && selections.length > 0
          ? new MultiScaleImageLayer({
              layerSource: channelSource,
              selections,
              transparentColor: slideHasChannels ? defaultTransparentColor : undefined,
              layerOpacities: stableSlideChannelOpacities,
              contrastLimits: stableSlideContrastLimits,
              colors: stableSlideChannelColors,
              gammaValues: stableSlideChannelsGamma,
              layersVisible: stableSlideChannelsVisible,
              excludeBackground:
                !overview &&
                // Only for rgb images
                interleavedSource &&
                some(stableSlideChannelOpacities, (opacity) => opacity > 0 && opacity < 100),
              overviewLayer: overview,
              viewerIndex: slide.viewerIndex,
              id: `${
                overview ? OVERVIEW_LAYER_ID : 'MultiScaleImageLayer'
              }-${channelSource.getUniqueId()}-${MAX_CHANNELS}`,

              tileSize: channelSource.getTileSize(),

              zoomOffset: 0,
              coordinateSystem: COORDINATE_SYSTEM.DEFAULT,
              pickable: true,
            })
          : null
      );
    });
  }, [
    channelSource,
    selections,
    slideHasChannels,
    stableSlideChannelsGamma,
    stableSlideChannelOpacities,
    stableSlideContrastLimits,
    stableSlideChannelColors,
    stableSlideChannelsVisible,
    overview,
  ]);

  return baseLayer ? [baseLayer] : [];
};
