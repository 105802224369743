import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { fromPairs, keys, some, times } from 'lodash';
import { useMemo } from 'react';
import { NumberParam, useQueryParam, useQueryParams } from 'use-query-params';

export const getActiveAnnotationAssignmentIdViewerKey = (viewerIndex: number) =>
  `activeAnnotationAssignmentIdViewer${viewerIndex}`;

export const useActiveAnnotationForViewer = (viewerIndex: number) =>
  useQueryParam(getActiveAnnotationAssignmentIdViewerKey(viewerIndex), NumberParam);

export const annotationAssignmentIdViewerQuerySchema = fromPairs(
  times(MAX_VIEWERS, (viewerIndex) => [getActiveAnnotationAssignmentIdViewerKey(viewerIndex), NumberParam])
);

export const useAnnotationQueryParams = () => {
  const [annotationQueryParams, setAnnotationQueryParams] = useQueryParams(annotationAssignmentIdViewerQuerySchema);
  const annotationQueryKeys = useMemo(() => keys(annotationQueryParams), [annotationQueryParams]);
  const annotationsActive = some(annotationQueryKeys, (key) => !isNaN(annotationQueryParams[key]));
  return { annotationsActive, annotationQueryParams, annotationQueryKeys, setAnnotationQueryParams };
};
