import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Autocomplete, Grid, TextField, useTheme } from '@mui/material';
import {
  compact,
  concat,
  Dictionary,
  filter,
  find,
  first,
  flatMap,
  isEmpty,
  join,
  map,
  orderBy,
  partition,
  sortBy,
  uniqBy,
} from 'lodash';
import moment from 'moment';
import React from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { Permission } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';

export const flattenSecondaryResult = (results: FeatureMetadata) =>
  compact([results, ...(results?.secondaryResults || [])]);

export const flattenSecondaryResults = (results: FeatureMetadata[]) => flatMap(results, flattenSecondaryResult);

export const useActiveSecondaryAnalysisStates = () =>
  useQueryParam<{
    [slideId: string]: { [viewerIndex: number]: { [primaryRunOrchestrationId: string]: number } };
  }>('activeSecondaryAnalysisStates', JsonParam);

export const getActiveSecondaryAnalysis = ({
  primaryRunResults,
  activeSecondaryAnalysisStates,
  slideId,
  viewerIndex,
}: {
  primaryRunResults: FeatureMetadata;
  slideId: string;
  viewerIndex: number;
  activeSecondaryAnalysisStates: Dictionary<Dictionary<Dictionary<number>>>;
}) => {
  const allFlattenedResults = flattenSecondaryResults([primaryRunResults]);
  const [flattenedResultsByCreatedAtDeleted, flattenedResultsByCreatedAtWithoutDeleted] = partition(
    orderBy(allFlattenedResults, ['createdAt'], ['desc']),
    'deletedAt'
  );

  const latestResultToDisplay =
    find(flattenedResultsByCreatedAtWithoutDeleted, { internallyApproved: true }) || // Use the first internally approved analysis (since secondary analysis will only use internally approved)
    first(flattenedResultsByCreatedAtWithoutDeleted); // Use the most recent analysis (since secondary analysis will only use internally approved)
  first(flattenedResultsByCreatedAtDeleted);

  const selectedSecondaryAnalysisExperimentId =
    activeSecondaryAnalysisStates?.[slideId]?.[viewerIndex]?.[primaryRunResults?.orchestrationId] ||
    latestResultToDisplay?.experimentResultId;

  return (
    find(allFlattenedResults, { experimentResultId: selectedSecondaryAnalysisExperimentId }) ||
    latestResultToDisplay ||
    // Should never happen, but just in case
    primaryRunResults
  );
};

export const useActiveResultForPrimaryOrchestrationId = ({
  allResults,
  primaryRunOrchestrationId,
  slideId,
  viewerIndex,
}: {
  allResults: FeatureMetadata[];
  primaryRunOrchestrationId: string;
  slideId: string;
  viewerIndex: number;
}) => {
  const resultsWithSecondaryResults = filter(allResults, (result) => !isEmpty(result?.secondaryResults));

  const uniqueResultsWithSecondaryResults = uniqBy(resultsWithSecondaryResults, (result) =>
    join(map(sortBy(result.secondaryResults, 'experimentResultId'), 'experimentResultId'), '-')
  );
  if (uniqueResultsWithSecondaryResults.length > 1) {
    console.warn(
      `Slide ${slideId}, primaryRunOrchestrationId ${primaryRunOrchestrationId} ` +
        `has multiple different secondary analysis results assigned to it's features / heatmaps.`,
      {
        uniqueResultsWithSecondaryResults,
        slideId,
        primaryRunOrchestrationId,
        viewerIndex,
        resultsWithSecondaryResults,
      }
    );
  }

  const primaryRunResults = first(uniqueResultsWithSecondaryResults);

  const isSecondaryAnalysis = Boolean(primaryRunResults);

  const [activeSecondaryAnalysisStates] = useActiveSecondaryAnalysisStates();

  const activeResult =
    isSecondaryAnalysis && primaryRunResults
      ? getActiveSecondaryAnalysis({ primaryRunResults, slideId, viewerIndex, activeSecondaryAnalysisStates })
      : first(allResults);

  const orchestrationId = activeResult?.orchestrationId;

  if (
    primaryRunOrchestrationId &&
    primaryRunOrchestrationId !== orchestrationId &&
    primaryRunOrchestrationId !== activeResult?.primaryRunOrchestrationId
  ) {
    console.warn(
      `Slide ${slideId}, primaryRunOrchestrationId ${primaryRunOrchestrationId} ` +
        `does not match the active result's orchestrationId ${orchestrationId} or primaryRunOrchestrationId ${activeResult?.primaryRunOrchestrationId}.`,
      {
        slideId,
        primaryRunOrchestrationId,
        orchestrationId,
        viewerIndex,
        activeResult,
        primaryRunResults,
        deletedAt: activeResult?.deletedAt,
        deletedBy: activeResult?.deletedBy,
      }
    );
  }

  const createdAt = activeResult?.createdAt;

  const isInternallyApproved = activeResult?.internallyApproved;

  const isApproved = activeResult?.approved;

  return {
    orchestrationId,
    isSecondaryAnalysis,
    createdAt,
    isInternallyApproved,
    isApproved,
    primaryRunResults,
    activeResult,
    deletedAt: activeResult?.deletedAt,
    deletedBy: activeResult?.deletedBy,
  };
};

export const getExperimentResultLabel = (result: FeatureMetadata, canSeeOrchestrationId: boolean) =>
  `${result?.primaryRunOrchestrationId ? 'Recalculated' : 'Primary'} - ${moment(result?.createdAt).format(
    'YYYY-MM-DD HH:mm'
  )}${result?.displayName ? ` - ${result?.displayName}` : ''}${
    canSeeOrchestrationId && result?.orchestrationId ? ` - ${result?.orchestrationId}` : ''
  }${
    result?.deletedAt
      ? ` (hidden on ${moment(result?.deletedAt).format('YYYY-MM-DD HH:mm')}${
          result?.deletedBy ? ` by ${result?.deletedBy}` : ''
        })`
      : ''
  }`;

export const ActiveSecondaryAnalysisResultsSelect: React.FC<{
  primaryRunResults: FeatureMetadata;
  slideId: string;
  viewerIndex: number;
  internalFeatures?: boolean;
}> = ({ primaryRunResults, slideId, viewerIndex, internalFeatures }) => {
  const { hasPermission } = usePermissions();
  const canPublishResults = hasPermission(Permission.PublishResults);
  const canSeeOrchestrationId = hasPermission(Permission.SeeOrchestrationId);
  const [activeSecondaryAnalysisStates, setActiveSecondaryAnalysisStates] = useActiveSecondaryAnalysisStates();
  const currentResult = primaryRunResults
    ? getActiveSecondaryAnalysis({ primaryRunResults, activeSecondaryAnalysisStates, slideId, viewerIndex })
    : undefined;
  const hasDeletedResults = Boolean(find(flattenSecondaryResults([primaryRunResults]), 'deletedAt'));
  const resultOptions = React.useMemo(() => {
    const allFlattenedResultsByCreatedAt = orderBy(
      filter(
        flattenSecondaryResult(primaryRunResults),
        (result) => canPublishResults || result?.approved || result?.internallyApproved
      ),
      ['createdAt'],
      ['desc']
    );
    const [flattenedResultsByCreatedAtDeleted, flattenedResultsByCreatedAtWithoutDeleted] = partition(
      allFlattenedResultsByCreatedAt,
      'deletedAt'
    );
    return map(concat(flattenedResultsByCreatedAtWithoutDeleted, flattenedResultsByCreatedAtDeleted), (result) => ({
      value: result?.experimentResultId,
      label: getExperimentResultLabel(result, canSeeOrchestrationId),
      deleted: Boolean(result?.deletedAt),
    }));
  }, [primaryRunResults, canPublishResults]);
  const theme = useTheme();
  return (
    (resultOptions || []).length > 1 && (
      <Grid
        item
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Autocomplete
          sx={
            !internalFeatures
              ? {
                  backgroundColor: theme.palette.primary.main,
                  '& .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment svg': {
                    color: 'white',
                  },
                  '& .MuiInput-root.MuiAutocomplete-inputRoot input': {
                    color: 'white',
                    paddingInlineStart: '4px',
                  },
                }
              : undefined
          }
          value={find(resultOptions, { value: currentResult?.experimentResultId ?? -1 })}
          onChange={(event, newValue) => {
            setActiveSecondaryAnalysisStates({
              ...activeSecondaryAnalysisStates,
              [slideId]: {
                ...activeSecondaryAnalysisStates?.[slideId],
                [viewerIndex]: {
                  ...activeSecondaryAnalysisStates?.[slideId]?.[viewerIndex],
                  [primaryRunResults.orchestrationId]: newValue?.value,
                },
              },
            });
          }}
          renderInput={(inputParams) => <TextField {...inputParams} fullWidth size="small" variant="standard" />}
          getOptionLabel={(option) => option.label}
          options={resultOptions}
          groupBy={hasDeletedResults ? (option) => (option.deleted ? 'Deleted' : 'Available') : undefined}
          clearIcon={<RestartAltIcon />}
          clearText={internalFeatures ? 'Return to approved results' : 'Return to published results'}
        />
      </Grid>
    )
  );
};
