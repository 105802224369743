import { COORDINATE_SYSTEM, Layer, PickingInfo } from '@deck.gl/core/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import GL from '@luma.gl/constants';
import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { FeatureCollection } from '@turf/helpers';
import { cloneDeep, filter, includes, isArray, isEmpty, times } from 'lodash';
import { EditableGeoJsonLayer, ViewMode } from 'nebula.gl';

import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { Permission } from 'interfaces/permissionOption';
import { useCallback, useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { usePermissions } from 'utils/usePermissions';
import { OrthographicMapViewState } from '../../OrthographicMapview';
import { AnnotationPolygonMode, AnnotationRectangleMode } from '../NebulaGLExtensions/editModes';
import { Feature } from '../NebulaGLExtensions/geojson-types';
import {
  RGBAColor,
  TOOLTIP_COLOR,
  getEditHandleColor,
  getEditHandleTypeFromEitherLayer,
} from '../NebulaGLExtensions/helpers';
import { useSecondaryAnalysisOrchestrationIdForViewer } from './useSecondaryAnalysisQueryParams';

const SECONDARY_ANALYSIS_EXCLUSION_COLOR: [number, number, number] = [255, 0, 0];
const SECONDARY_ANALYSIS_INCLUSION_COLOR: [number, number, number] = [0, 255, 0];

// TODO: not ideal to use a global constant, but we want a stable reference to the same signal across renders
// This is a workaround for the fact that nebula.gl always needs data to work with, even if it's empty
const NO_SECONDARY_ANALYSIS_SELECTIONS: FeatureCollection = { type: 'FeatureCollection', features: [] };

export const secondaryAnalysisPolygons = times(MAX_VIEWERS, () => signal<FeatureCollection | null>(null));

export const secondaryAnalysisInclusionModes = ['include-polygon', 'include-roi'];
export const secondaryAnalysisExclusionModes = ['exclude-polygon', 'exclude-roi'];

const getFillColor = (feature: Feature): RGBAColor =>
  includes(secondaryAnalysisInclusionModes, feature?.properties?.featureType)
    ? [...SECONDARY_ANALYSIS_INCLUSION_COLOR, 50]
    : includes(secondaryAnalysisExclusionModes, feature?.properties?.featureType)
    ? [...SECONDARY_ANALYSIS_EXCLUSION_COLOR, 50]
    : [0, 0, 0, 50];

const getLineColor = (feature: Feature): RGBAColor =>
  includes(secondaryAnalysisInclusionModes, feature?.properties?.featureType)
    ? [...SECONDARY_ANALYSIS_INCLUSION_COLOR, 100]
    : includes(secondaryAnalysisExclusionModes, feature?.properties?.featureType)
    ? [...SECONDARY_ANALYSIS_EXCLUSION_COLOR, 100]
    : [0, 0, 0, 50];

export const useSecondaryAnalysisLayer = ({
  slide,
  viewState,
  viewSize,
}: {
  slide: SlideWithChannelAndResults;
  viewState: OrthographicMapViewState;
  viewSize: { width: number; height: number };
}) => {
  useSignals();
  const [secondaryAnalysisAreaSelectionMode] = useQueryParam('secondaryAnalysisAreaSelectionMode', StringParam);
  const [secondaryAnalysisOrchestrationId] = useSecondaryAnalysisOrchestrationIdForViewer(slide.viewerIndex);

  const { hasPermission } = usePermissions();
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);

  const secondaryAnalysisFeatureCollection =
    secondaryAnalysisPolygons[slide.viewerIndex].value || NO_SECONDARY_ANALYSIS_SELECTIONS;

  const deleteFeature: DeckGLProps['onClick'] = useCallback(
    (pickingInfo) => {
      const featureIndex = pickingInfo?.index;
      const polygonsBeforeDelete = secondaryAnalysisPolygons[slide.viewerIndex].value;
      if (
        !polygonsBeforeDelete ||
        featureIndex === undefined ||
        !isArray(polygonsBeforeDelete.features) ||
        !polygonsBeforeDelete.features[featureIndex]
      ) {
        console.warn('No feature to delete', { polygonsBeforeDelete, featureIndex });
        return;
      }
      const updatedData = cloneDeep(polygonsBeforeDelete);
      updatedData.features.splice(featureIndex, 1);
      secondaryAnalysisPolygons[slide.viewerIndex].value = updatedData;
    },
    [slide.viewerIndex]
  );

  const onClick = useCallback(
    (info: PickingInfo, event: Parameters<DeckGLProps['onClick']>[1]) => {
      if (secondaryAnalysisAreaSelectionMode && event.rightButton) {
        event.preventDefault();
        event.stopPropagation();
        return true;
      }
      if (secondaryAnalysisAreaSelectionMode === 'delete-polygon') {
        // @ts-ignore
        deleteFeature(info, event);
      }
    },
    [secondaryAnalysisAreaSelectionMode, deleteFeature]
  );

  const onEdit = useCallback(
    ({ updatedData, editContext }: { updatedData: FeatureCollection; editType: string; editContext: any }) => {
      times(updatedData?.features?.length ?? 0, (featureIndex) => {
        const editedFeatureWithoutChanges = secondaryAnalysisFeatureCollection?.features?.[featureIndex];
        const isNewFeature = !editedFeatureWithoutChanges;

        if (isNewFeature && editContext?.feature?.properties?.guideType !== 'tentative') {
          updatedData.features[featureIndex].properties.featureType = secondaryAnalysisAreaSelectionMode;
          updatedData.features[featureIndex].properties.orchestrationId = secondaryAnalysisOrchestrationId;
          console.info('Adding new feature', { feature: updatedData.features[featureIndex] });
        }
      });
      updatedData.features = filter(
        updatedData.features,
        (feature) =>
          // Only keep tentative features or features that match the current orchestrationId
          !feature.properties.orchestrationId || feature.properties.orchestrationId === secondaryAnalysisOrchestrationId
      );
      secondaryAnalysisPolygons[slide.viewerIndex].value = updatedData;
    },
    [
      secondaryAnalysisAreaSelectionMode,
      secondaryAnalysisFeatureCollection?.features,
      secondaryAnalysisOrchestrationId,
      slide?.viewerIndex,
    ]
  );

  const selectedFeatureIndexes = useMemo(
    () => times(secondaryAnalysisFeatureCollection?.features?.length || 0, (i) => i),
    [secondaryAnalysisFeatureCollection?.features?.length]
  );

  const secondaryAnalysisLayer =
    canRunSecondaryAnalysis &&
    secondaryAnalysisOrchestrationId &&
    (secondaryAnalysisAreaSelectionMode || !isEmpty(secondaryAnalysisFeatureCollection?.features)) &&
    // @ts-ignore
    (new EditableGeoJsonLayer({
      id: `secondaryAnalysis-${slide.id}`,
      coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      getRadius: 2,
      data: secondaryAnalysisFeatureCollection,
      selectedFeatureIndexes,
      // @ts-ignore
      onClick,
      onEdit,
      mode: includes(['include-polygon', 'exclude-polygon'], secondaryAnalysisAreaSelectionMode)
        ? AnnotationPolygonMode
        : includes(['include-roi', 'exclude-roi'], secondaryAnalysisAreaSelectionMode)
        ? AnnotationRectangleMode
        : ViewMode,
      modeConfig: {
        viewport: { ...viewState, ...viewSize },
        ...(secondaryAnalysisAreaSelectionMode ? { lockRectangles: true } : {}),
      },
      pickable: true,
      autoHighlight: secondaryAnalysisAreaSelectionMode === 'delete-polygon',
      highlightColor: [255, 255, 255, 25] as RGBAColor,
      stroked: true,
      filled: true,
      editHandleType: 'point',
      getFillColor,
      getLineColor,
      _subLayerProps: {
        tooltips: { getColor: TOOLTIP_COLOR },
      },
      getEditHandleIcon: getEditHandleTypeFromEitherLayer,
      getEditHandleIconSize: 40,
      getEditHandleIconColor: getEditHandleColor,

      pointRadiusMinPixels: 5,
      pointRadiusScale: 2,
      lineWidthScale: 2,
      lineWidthMinPixels: 2,

      parameters: {
        depthTest: true,
        depthMask: false,

        blend: true,
        blendEquation: GL.FUNC_ADD,
        blendFunc: [GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA],
      },
    }) as Layer<any>);

  return secondaryAnalysisLayer;
};
