import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { filter, fromPairs, includes, isEmpty, keys, lowerCase, map } from 'lodash';
import React, { useMemo, useState } from 'react';

import Loader from 'components/Loader';
import { useSlideAnnotationLayersData } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/useSlideAnnotationLayersData';
import { Permission } from 'interfaces/permissionOption';
import { useActiveAnnotationForViewer } from 'services/annotations/useAnnotationQueryParams';
import { usePermissions } from 'utils/usePermissions';
import { SlideWithChannelAndResults } from '../../../useSlideChannelsAndResults/utils';
import { GroupedLayersVisualControls } from '../../GroupedLayersVisualControls';
import { AnnotationChangesControls } from './AnnotationChangesControls';
import { AnnotationSelection } from './AnnotationSelection';
import { getAnnotationLabel, getAnnotationTodoNameAndDefinition } from './helpers';
import { useUpdateAnnotationHeatmapsSettingsOnChange } from './useUpdateAnnotationHeatmapsSettingsOnChange';

export const SlideAnnotation: React.FC<{
  slide: SlideWithChannelAndResults;
  filterText: string;
  enabled?: boolean;
}> = ({ slide, filterText, enabled }) => {
  useSignals();

  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const [activeAnnotationAssignmentId] = useActiveAnnotationForViewer(slide?.viewerIndex);

  const { slideAnnotations, isLoading, isError } = useSlideAnnotationLayersData({ slideId: slide?.id, enabled });

  useUpdateAnnotationHeatmapsSettingsOnChange({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
    stainTypeId: slide?.stainingType,
    slideAnnotations,
    activeAnnotationAssignmentId,
  });

  const groupedFilteredAnnotationLayers = useMemo(() => {
    const lowerCaseFilter = lowerCase(filterText);
    return fromPairs(
      map(
        activeAnnotationAssignmentId
          ? filter(slideAnnotations, { annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId } })
          : slideAnnotations,
        (annotation): [string, string[]] => {
          const { todo } = getAnnotationTodoNameAndDefinition(annotation);

          const todoOptions = todo?.options;

          const filteredOptions = lowerCaseFilter
            ? filter(
                todoOptions,
                (option) =>
                  includes(lowerCase(option.name), lowerCaseFilter) ||
                  includes(lowerCase(option.displayName || option.name), lowerCaseFilter)
              )
            : todoOptions;

          return [getAnnotationLabel(annotation), map(filteredOptions, 'name')];
        }
      )
    );
  }, [slideAnnotations, activeAnnotationAssignmentId, filterText]);

  const [expandAccordion, setExpandAccordion] = useState(false);

  return isError ? (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item md={12}>
        <Typography variant="h4">Error loading slide annotations</Typography>
      </Grid>
    </Grid>
  ) : (
    <Accordion square expanded={expandAccordion} onChange={() => setExpandAccordion(!expandAccordion)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingInline: 1 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={12}>
            <Typography variant="h3">Annotations (Beta)</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {canAnnotateSlides && slideAnnotations && (
              <AnnotationSelection slideAnnotations={slideAnnotations} viewerIndex={slide?.viewerIndex} />
            )}
            <AnnotationChangesControls viewerIndex={slide?.viewerIndex} />
            {!isEmpty(groupedFilteredAnnotationLayers) && (
              <GroupedLayersVisualControls
                viewerIndex={slide?.viewerIndex}
                slideId={slide?.id}
                stainTypeId={slide?.stainingType}
                disableToggle={canAnnotateSlides && !isNaN(activeAnnotationAssignmentId)}
                groupedLayers={groupedFilteredAnnotationLayers}
                defaultExpanded={
                  !isNaN(activeAnnotationAssignmentId) ? keys(groupedFilteredAnnotationLayers) : undefined
                }
              />
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default SlideAnnotation;
