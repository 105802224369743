import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { cloneDeep, find, flatMap, map, times } from 'lodash';
import { useMemo } from 'react';

import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { Annotation } from 'interfaces/annotation';
import { Permission } from 'interfaces/permissionOption';
import annotationsService from 'services/annotations/annotationsService';
import { useActiveAnnotationForViewer } from 'services/annotations/useAnnotationQueryParams';
import { usePermissions } from 'utils/usePermissions';
import { FeatureCollection } from '../NebulaGLExtensions/geojson-types';
import { useSlideAnnotationLayersData } from '../useSlideAnnotationLayersData';

export const viewerAnnotationData = times(MAX_VIEWERS, () => signal<FeatureCollection | null>(null));

export const useActiveAnnotationDraft = ({
  slideId,
  viewerIndex,
}: {
  slideId: string;
  viewerIndex: number;
}): {
  slideAnnotations: Annotation[];
  activeAnnotationData: FeatureCollection | null;
  isLoading: boolean;
} => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canViewAnnotations = hasPermission(Permission.ViewAnnotations);

  const { slideAnnotations, isLoading } = useSlideAnnotationLayersData({ slideId });

  const [activeAnnotationAssignmentId] = useActiveAnnotationForViewer(viewerIndex);

  const liveAnnotationData = viewerAnnotationData[viewerIndex]?.value;

  const savedAnnotationData = canViewAnnotations
    ? find(slideAnnotations, { annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId } })
    : undefined;

  const featureCollectionFromAnnotationData = useMemo(() => {
    return {
      type: 'FeatureCollection' as const,
      features: flatMap(savedAnnotationData?.annotationsData, (savedData, annotationIndex) => {
        const innerTodo = annotationsService.deserializeAnnotationObject(cloneDeep(savedData), true);
        if (!innerTodo?.markers) {
          console.warn('Annotation is missing markers', { innerTodo });
          return [];
        }

        return map(
          innerTodo?.markers?.features,
          (feature): FeatureCollection['features'][number] =>
            ({
              ...feature,
              properties: {
                ...feature.properties,
                annotationIndex,
                annotationId: savedAnnotationData.annotationId,
                todo: innerTodo.todo,
              },
            } as FeatureCollection['features'][number])
        );
      }),
    };
  }, [canViewAnnotations, savedAnnotationData, liveAnnotationData]);

  return {
    activeAnnotationData:
      !isLoading && canViewAnnotations && savedAnnotationData
        ? liveAnnotationData || featureCollectionFromAnnotationData
        : null,
    isLoading,
    slideAnnotations,
  };
};
