import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { groupBy, isEmpty, sortBy } from 'lodash';
import React, { useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { ProtomapTree } from './ProtomapTileControl';
import { RasterHeatmapTree } from './RasterHeatmaps';
import { UnpublishResultsButton } from './UnpublishResultsButton';

interface HeatmapsProps {
  viewerIndex: number;
  title: string;
  heatmaps: FeatureMetadata[];
  slideId: string;
  stainTypeId: string;
  studyId: string;
  filterText: string;
  expandByDefault?: boolean;
  internalHeatmaps?: boolean;
  hideOrchestrationId?: boolean;
}

const Heatmaps: React.FunctionComponent<React.PropsWithChildren<HeatmapsProps>> = ({
  viewerIndex,
  title,
  heatmaps,
  stainTypeId,
  slideId,
  studyId,
  filterText,
  expandByDefault,
  internalHeatmaps = false,
  hideOrchestrationId,
}) => {
  useSignals();
  const { dzi: dziHeatmaps, pmt: pmtHeatmaps } = groupBy(sortBy(heatmaps, 'displayName'), 'heatmapType');

  const [expandAccordion, setExpandAccordion] = useState(Boolean(expandByDefault));

  const [isPublishMode] = useQueryParam(`isPublishModeViewer${viewerIndex}`, BooleanParam);
  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);

  return (
    <Accordion square expanded={expandAccordion} onChange={() => setExpandAccordion(!expandAccordion)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={isPublishMode && !internalHeatmaps ? 7 : 12}>
            <Typography variant={internalHeatmaps ? 'subtitle2' : 'h4'}>{title}</Typography>
          </Grid>
          <UnpublishResultsButton
            slideId={slideId}
            viewerIndex={viewerIndex}
            studyId={studyId}
            internalHeatmaps={internalHeatmaps}
            heatmaps={heatmaps}
          />
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        {!isEmpty(dziHeatmaps) && (
          <RasterHeatmapTree
            viewerIndex={viewerIndex}
            slideId={slideId}
            heatmaps={dziHeatmaps}
            stainTypeId={stainTypeId}
            filterText={filterText}
            onEmptyFilter={() => setExpandAccordion(true)}
            hideOrchestrationId={hideOrchestrationId}
          />
        )}
        {useDeckGL && !isEmpty(pmtHeatmaps) && (
          <ProtomapTree
            viewerIndex={viewerIndex}
            slideId={slideId}
            pmtHeatmaps={pmtHeatmaps}
            stainTypeId={stainTypeId}
            filterText={filterText}
            onEmptyFilter={() => setExpandAccordion(true)}
            hideOrchestrationId={hideOrchestrationId}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default Heatmaps;
