import { DeckGLProps } from '@deck.gl/react/typed';
import { useSignals } from '@preact/signals-react/runtime';
import { compact, pickBy, sum, values } from 'lodash';
import { ArrayParam, StringParam, useQueryParam } from 'use-query-params';

import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { HeatmapsImagePyramids, ImagePyramid } from 'components/Procedure/useSlideImages';
import { useMemo } from 'react';
import { OrthographicMapViewState } from '../OrthographicMapview';
import { useEditAnnotationLayers } from './EditAnnotationLayers/useEditAnnotationLayers';
import { useMeasureToolLayer } from './MeasureToolLayer';
import { useSecondaryAnalysisLayer } from './SecondaryAnalysisLayer/useSecondaryAnalysisLayer';
import { multiScaleLayerLoadingStates } from './StainsLayers/layers/multiScaleImageLayer/getTileData';
import { useBaseSlideLayers } from './useBaseSlideLayers';
import { useDeckGLGeoJSONCellLayers } from './useDeckGLGeoJSONCellLayers';
import { useHeatmapLayers } from './useHeatmapLayers';
import { usePinCommentsLayer } from './usePinCommentsLayer';
import { usePmtLayers } from './usePmtLayers';
import { useViewAnnotationLayers } from './useViewAnnotationLayers';

export const useSlideLayers = ({
  slide,
  baseImagePyramids,
  heatmapsImagePyramids,
  procedureId,
  viewSize,
  viewState,
  hideComments,
}: {
  slide: SlideWithChannelAndResults;
  baseImagePyramids: ImagePyramid;
  heatmapsImagePyramids: HeatmapsImagePyramids;
  procedureId: number;
  viewState: OrthographicMapViewState;
  viewSize: { width: number; height: number };
  hideComments?: boolean;
}) => {
  useSignals();

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];

  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings.value?.[slide.id];

  const viewAnnotationLayers = useViewAnnotationLayers({ slide });
  const editAnnotationLayer = useEditAnnotationLayers({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
    viewState,
    viewSize,
  });
  const secondaryAnalysisLayer = useSecondaryAnalysisLayer({ slide, viewState, viewSize });
  const measureToolLayer = useMeasureToolLayer({
    slideId: slide.id,
    maxResolution: slide.maxResolution,
  });
  const [commentMode] = useQueryParam('commentMode', StringParam);

  const { editCommentLayer, addCommentLayer } = usePinCommentsLayer({
    slideId: slide.id,
    procedureId,
    viewerIndex: slide.viewerIndex,
    mode: commentMode === 'addPoint' ? 'addPoint' : 'edit',
  });

  const pmtLayers = usePmtLayers({
    idPrefix: 'slide-layer',
    slide,
    maxLevel: baseImagePyramids?.layerSource?.maxLevel ?? 0,
  });

  const [geoJSONTestUrls] = useQueryParam('geoJSONTestUrls', ArrayParam);
  const geoJSONLayers = useDeckGLGeoJSONCellLayers({
    slideLayerVisualizationSettings,
    geoJSONUrls: geoJSONTestUrls,
    baseImagePyramids,
  });
  const baseSlideLayers = useBaseSlideLayers({ slide, baseImagePyramids });
  const heatmapLayers = useHeatmapLayers({ slide, baseImagePyramids, heatmapsImagePyramids });
  const layers: DeckGLProps['layers'] = compact([
    ...baseSlideLayers,
    ...heatmapLayers,
    ...pmtLayers,
    ...geoJSONLayers,
    ...(!hideComments && editCommentLayer ? [editCommentLayer] : []),
    ...(measureToolLayer ? [measureToolLayer] : []),
    ...(commentMode === 'addPoint'
      ? [...(addCommentLayer ? [addCommentLayer] : [])]
      : [
          ...(secondaryAnalysisLayer ? [secondaryAnalysisLayer] : []),
          ...(editAnnotationLayer ? [editAnnotationLayer] : viewAnnotationLayers ? viewAnnotationLayers : []),
        ]),
  ]);

  const loadingStates = multiScaleLayerLoadingStates[slide.viewerIndex]?.value;
  // Heatmap URLs include the word 'heatmap' in them. We can improve the loading states naming in the future.
  const { heatmapLoadingStates, baseSlideLoadingStates } = useMemo(
    () => ({
      heatmapLoadingStates: pickBy(loadingStates, (value, key) => key.includes(slide.id) && key.includes('heatmap')),
      baseSlideLoadingStates: pickBy(loadingStates, (value, key) => key.includes(slide.id) && !key.includes('heatmap')),
    }),
    [(JSON.stringify(loadingStates), slide.id)]
  );
  const numLoadingBaseLayers = sum(values(heatmapLoadingStates));
  const numLoadingHeatmapLayers = sum(values(baseSlideLoadingStates));
  const numBaseLayers = values(heatmapLoadingStates).length;
  const numHeatmapLayers = values(baseSlideLoadingStates).length;

  return {
    numLoadingBaseLayers,
    numBaseLayers,
    numLoadingHeatmapLayers,
    numHeatmapLayers,
    layers,
    interactiveLayer:
      commentMode !== 'addPoint' && (editAnnotationLayer || secondaryAnalysisLayer)
        ? editAnnotationLayer || secondaryAnalysisLayer
        : commentMode === 'addPoint'
        ? addCommentLayer
        : undefined,
  };
};
