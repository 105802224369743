import { useSignals } from '@preact/signals-react/runtime';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { forEach, isEmpty } from 'lodash';
import { useEffect } from 'react';

import usePrevious from 'utils/usePrevious';
import {
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
  useGetLayerSettingsFromUrl,
} from '../../../slidesVisualizationAndConfiguration';
import { computeDefaultPmtLayerSettings, getPmtLayerId } from './ProtomapTileControl';

export const useUpdatePmtHeatmapsSettingsOnChange = ({
  slideId,
  viewerIndex,
  stainTypeId,
  layerIdsToUrlKeys,
  pmtHeatmaps,
}: {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  layerIdsToUrlKeys?: Record<string, string>;
  pmtHeatmaps: FeatureMetadata[];
}) => {
  useSignals();
  const getLayerSettingsFromUrl = useGetLayerSettingsFromUrl();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const previousSlideParams = usePrevious({ slideId, viewerIndex });
  useEffect(() => {
    if (previousSlideParams?.slideId === slideId && previousSlideParams?.viewerIndex === viewerIndex) {
      return;
    }
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
    const changes: Array<{
      layerId: string;
      layerUrlKey?: string;
      newSettings: Partial<LayerVisualizationSettings>;
    }> = [];
    forEach(pmtHeatmaps, (pmtHeatmap) => {
      const heatmapOptions = pmtHeatmap?.nestedItems || [];
      if (slideId && !isEmpty(heatmapOptions)) {
        forEach(heatmapOptions, (heatmapOption, layerIndex) => {
          const layerName = heatmapOption?.key;
          const computedLayerSettings = computeDefaultPmtLayerSettings(pmtHeatmap, layerIndex, layerName);
          const layerSettingsKey = getPmtLayerId(pmtHeatmap, layerName);
          const previousSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[layerSettingsKey];
          const layerUrlKey = layerIdsToUrlKeys?.[layerSettingsKey] || layerSettingsKey;
          const urlSettings = getLayerSettingsFromUrl({ layerUrlKey, stainTypeId, viewerIndex });
          changes.push({
            layerId: layerSettingsKey,
            newSettings: {
              ...computedLayerSettings,
              ...previousSettings,
              ...urlSettings,
            },
          });
        });
      }
    });
    applyChangesToSlideLayerVisualizationSettings({ slideId, viewerIndex, changes, skipUrlUpdate: true });
  }, [viewerIndex, slideId, stainTypeId, pmtHeatmaps, layerIdsToUrlKeys, getLayerSettingsFromUrl]);
};
