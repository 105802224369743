import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { viewerAnnotationData } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useActiveAnnotationDraft';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import React from 'react';
import { useActiveAnnotationForViewer } from 'services/annotations/useAnnotationQueryParams';
import { StringParam, useQueryParam } from 'use-query-params';

export interface AnnotationChangesControlsProps {
  viewerIndex: number;
}

export const AnnotationChangesControls: React.FC<React.PropsWithChildren<AnnotationChangesControlsProps>> = ({
  viewerIndex,
}) => {
  // TODO: move to another component
  const [editAnnotationsMode, setEditAnnotationsMode] = useQueryParam('editAnnotationsMode', StringParam);
  const [, setActiveAnnotationAssignmentId] = useActiveAnnotationForViewer(viewerIndex);

  const confirmWithModal = useConfirmation();

  return (
    <>
      {editAnnotationsMode && (
        <Grid item>
          <ToggleButtonGroup
            exclusive
            onChange={async (event, value) => {
              if (
                value === 'cancel' &&
                (await confirmWithModal({
                  title: 'Cancel annotation changes',
                  text: 'Are you sure you want to cancel?',
                  confirmButtonProps: { title: 'Cancel' },
                  cancelButtonProps: { title: 'Continue' },
                }))
              ) {
                setEditAnnotationsMode(null);
                if (viewerAnnotationData[viewerIndex]) {
                  viewerAnnotationData[viewerIndex].value = null;
                }
              } else if (
                value === 'save' &&
                (await confirmWithModal({
                  title: 'Save annotation',
                  text: 'Are you sure you want to save the annotation?',
                  confirmButtonProps: { title: 'Save' },
                  cancelButtonProps: { title: 'Cancel' },
                }))
              ) {
                // TODO: save annotation
                setActiveAnnotationAssignmentId(null);
                setEditAnnotationsMode(null);
              }
            }}
          >
            {/* TODO: implement saving */}
            <ToggleButton value="save" disabled>
              Save (will be available soon)
            </ToggleButton>
            <ToggleButton value="cancel">Cancel</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
    </>
  );
};
