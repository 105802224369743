import { useQuery } from '@tanstack/react-query';
import { getCaseSlideThumbnailsData, getProcedureIds } from 'api/procedures';
import { getSlidesViewerNavigationPrefetchData } from 'api/slides';
import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import { CaseSlideThumbnailData } from 'interfaces/procedure';
import { SlidePrefetchData } from 'interfaces/slide';
import { filter, find, findIndex, first, flatMap, includes, isEmpty, isNumber, map, some } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { usePaginatedQueries } from 'utils/usePaginatedQueries';

export const useFilteredCaseIds = (caseId: number, studyId?: string) => {
  const { generateEncodedParams, queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const idsEncodedParams = generateEncodedParams({
    filters: {
      ...queryParams?.filters,
      ...(studyId && isEmpty(queryParams?.filters) ? { studyId: studyId } : {}),
    },
    // The page is not important, the response return all the ids
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  return useQuery(['procedureIds', [idsEncodedParams]], {
    queryFn: ({ signal }) =>
      studyId ? getProcedureIds(idsEncodedParams) : getProcedureIds(idsEncodedParams, caseId, signal),
    keepPreviousData: true,
  });
};

export const useFilteredCaseSlideThumbnailsData = ({
  studyId,
  enabled = true,
}: { studyId?: string; enabled?: boolean } = {}) => {
  const { generateEncodedParams, queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const idsEncodedParams = generateEncodedParams({
    filters: {
      ...queryParams?.filters,
      ...(studyId && !queryParams?.filters?.studyId ? { studyId: studyId } : {}),
    },
    // The page / pageSize is not important, the response return all the ids
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  return useQuery(['slideThumbnailsData', [idsEncodedParams]], {
    queryFn: ({ signal }) => getCaseSlideThumbnailsData(idsEncodedParams, signal),
    keepPreviousData: true,
    enabled,
  });
};

export const useFilteredSlidesViewerNavigationPrefetchData = ({
  studyId,
  enabled = true,
  numSurroundingPagesToPrefetch: numSurroundingPagesToPrefetchInput = 1,
  focusedCaseId,
  focusedSlideId,
}: {
  studyId?: string;
  enabled?: boolean;
  numSurroundingPagesToPrefetch?: number;
  focusedCaseId?: number;
  focusedSlideId?: string;
} = {}): {
  data: SlidePrefetchData[];
  isLoading: boolean;
  isInitialLoading: boolean;
} => {
  const cantGetCurrentPage = !isNumber(focusedCaseId) && !focusedSlideId;
  if (cantGetCurrentPage) {
    console.warn("No case or slides in the page to prefetch - can't determine the current page");
  }
  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);

  const { generateEncodedParams, queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const allSlideThumbnailsQuery = useFilteredCaseSlideThumbnailsData({ studyId, enabled });
  const slideThumbnailsData = filter(allSlideThumbnailsQuery?.data, ({ caseId }) =>
    pendingSlidesMode ? caseId == null : caseId !== null
  );

  const pageSize = DEFAULT_PAGE_SIZE;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numSurroundingPagesToPrefetchState, setNumSurroundingPagesToPrefetchState] = useState<number | null>(null);
  const numSurroundingPagesToPrefetch = numSurroundingPagesToPrefetchState ?? numSurroundingPagesToPrefetchInput;

  useEffect(() => {
    if (cantGetCurrentPage) {
      return;
    }
    let pagesMatchingCaseOrSlides: number[] = [];
    const matchingPatterns: Partial<CaseSlideThumbnailData>[] = focusedSlideId
      ? map(focusedSlideId, (slideId) => (isNumber(focusedCaseId) ? { caseId: focusedCaseId, slideId } : { slideId }))
      : [{ caseId: focusedCaseId }];
    const matchingIndices = filter(
      map(matchingPatterns, (pattern) => findIndex(slideThumbnailsData, pattern)),
      (index) => index >= 0
    );
    if (isEmpty(matchingIndices)) {
      console.warn(
        `No case or slides in the page to prefetch - no matching case or slides found, prefetching page ${currentPage}`
      );
      return;
    } else {
      pagesMatchingCaseOrSlides = map(matchingIndices, (index) => Math.floor(index / pageSize) + 1);
    }
    if (!includes(pagesMatchingCaseOrSlides, currentPage)) {
      console.info(
        `Current page ${currentPage} does not match any of the pages with the case or slides in the page. Prefetching the first page with the case or slides.`,
        { currentPage, pagesMatchingCaseOrSlides }
      );
      setCurrentPage(first(pagesMatchingCaseOrSlides));
      if (pagesMatchingCaseOrSlides.length > numSurroundingPagesToPrefetch) {
        setNumSurroundingPagesToPrefetchState(pagesMatchingCaseOrSlides.length);
      }
    } else {
      setNumSurroundingPagesToPrefetchState(null);
    }
  }, [
    slideThumbnailsData,
    cantGetCurrentPage,
    focusedCaseId,
    focusedSlideId,
    numSurroundingPagesToPrefetchInput,
    pageSize,
  ]);

  const filters = {
    ...queryParams?.filters,
    ...(studyId && !queryParams?.filters?.studyId ? { studyId: studyId } : {}),
    pageSize,
    page: currentPage,
  };

  const totalSlides = slideThumbnailsData?.length || 0;
  const totalPages = Math.ceil(totalSlides / pageSize);
  const paginatedQueries = usePaginatedQueries({
    pageQueryFn: (pageArgs, { signal }) => getSlidesViewerNavigationPrefetchData(pageArgs, signal),
    getPageArgs: (page) =>
      generateEncodedParams(
        { filters, page: page + 1, pageSize },
        { pendingSlidesMode },
        { pendingSlidesMode: BooleanParam }
      ),
    getQueryKeyForPage: (page) => [
      'slidesViewerNavigationPrefetchData',
      generateEncodedParams(
        { filters, page: page + 1, pageSize },
        { pendingSlidesMode },
        { pendingSlidesMode: BooleanParam }
      ),
    ],
    currentPage,
    totalPages,
    numSurroundingPagesToPrefetch,
    getItemsFromPageData: (data) => data,
    getPlaceholderItems: () => [],
  });

  const data = useMemo(() => {
    const allPaginatedItems = flatMap(paginatedQueries.items, ({ items }) => items);
    return map(
      slideThumbnailsData,
      (caseThumbnailsData): SlidePrefetchData =>
        find(allPaginatedItems, {
          caseId: caseThumbnailsData.caseId,
          slideId: caseThumbnailsData.slideId,
        }) || caseThumbnailsData
    );
  }, [paginatedQueries.items, slideThumbnailsData]);

  const isLoading =
    allSlideThumbnailsQuery.isLoading || some(paginatedQueries.queries, (query) => query.isLoading && query.isFetching);
  const isInitialLoading =
    allSlideThumbnailsQuery.isInitialLoading || some(paginatedQueries.queries, 'isInitialLoading');

  const res = { data, isLoading, isInitialLoading };

  return res;
};
