import { createTheme, PaletteOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    custom?: {
      background?: {
        transparent: {
          white: string;
          black: string;
        };
      };
    };

    softContrast?: Palette['primary'];
  }
  interface PaletteOptions {
    custom?: {
      background?: {
        transparent: {
          white: string;
          black: string;
        };
      };
    };
    softContrast?: PaletteOptions['primary'];
  }
}

const themeColors: Partial<PaletteOptions> = {
  primary: {
    main: '#be62a5',
    light: '#d8a4ca',
  },
  secondary: {
    main: '#6b7c93',
    light: '#d8dce2',
  },
  warning: {
    main: '#f3bb45',
    light: '#f9d79c',
  },
  error: {
    main: '#d32f2f',
    light: '#f79999',
    dark: '#a85959',
  },
  softContrast: {
    light: '#F9F9FB',
    main: '#D8DCE2',
    contrastText: '#6B7C93',
  },
};

const customStyles = {
  background: {
    transparent: {
      white: 'rgba(255, 255, 255, 0.5)',
      black: 'rgba(0, 0, 0, 0.5)',
    },
  },
};

const themeTypography = {
  typography: {
    fontFamily: 'Ubuntu, sans-serif',
    h1: {
      fontSize: 32,
      fontWeight: 700,
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
    },
    h3: {
      fontSize: 18,
      fontWeight: 700,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '0.8rem',
    },
    subtitle1: {
      fontSize: 16,
    },
  },
};

const components = {
  MuiCardHeader: {
    styleOverrides: {
      title: {
        fontSize: 16,
        fontWeight: 700,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: 20,
        minWidth: 600,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: 16,
        fontWeight: 700,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        maxWidth: 300,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  MuiDialog: {
    defaultProps: {
      PaperProps: {
        onClick: (e: any) => {
          e.stopPropagation();
        },
      },
    },
  },
};

export const lightTheme = createTheme({
  ...themeTypography,
  palette: {
    mode: 'light',
    ...themeColors,
    custom: customStyles,
  },
  components: {
    ...components,
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        fullWidth: true,
      },
    },
  },
});

export const darkTheme = createTheme({
  ...themeTypography,
  palette: {
    mode: 'dark',
    ...themeColors,
    custom: customStyles,
  },
  components: {
    ...components,
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'filled',
        fullWidth: true,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'filled',
      },
    },
  },
});

export const defaultLayerColors = [
  '#0000FF',
  '#FFFF00',
  '#FF7600',
  '#00FFFF',
  '#FF00FF',
  '#00FF00',
  '#002DB3',
  '#B3B300',
  '#FF0000',
  '#BEEEDB',
  '#AA00FF',
  '#80FFBF',
  '#BFCFFF',
  '#EEEE80',
  '#FF8080',
  '#26DBFF',
  '#7700B3',
  '#80FF80',
  '#809FFF',
  '#D99926',
  '#B30000',
  '#00B3B3',
  '#EABFFF',
  '#00B300',
  '#00209F',
  '#DEEEAF',
  '#D92676',
  '#80EEEE',
  '#D580FF',
  '#4CDB00',
  '#0062AD',
  '#B9D9C3',
  '#B36200',
  '#BFE6FF',
  '#7E75AA',
  '#9CAD9C',
  '#6262FF',
  '#626200',
  '#FFC680',
  '#5A8080',
  '#E7B6FF',
  '#5A805A',
  '#BFD9FF',
  '#808062',
  '#D9B362',
  '#ACBFBF',
  '#7B7380',
  '#BFFFE6',
  '#ACB1BF',
  '#FFE2BF',
  '#E6BFBF',
  '#758282',
  '#B9ACBF',
  '#ACBFAC',
  '#BFBFBF',
  '#676767',
];
