import { useQueries } from '@tanstack/react-query';
import { compact, filter, find, first, fromPairs, includes, isEmpty, map, times } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import { getSlideById, getSlideByIdKeys } from 'api/slides';
import { Procedure } from 'interfaces/procedure';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { useProceduresWithQAExperimentResultsOnly } from 'utils/useProceduresWithQAExperimentResultsOnly';
import { useFilteredSlidesViewerNavigationPrefetchData } from './useFilteredCaseIds';

export const usePrefetchedCaseData = ({
  caseId,
  focusedSlideId,
  studyId: studyIdFromProps,
  enabled = true,
}: {
  caseId?: number;
  focusedSlideId?: string;
  studyId?: string;
  enabled?: boolean;
}) => {
  const params = useParams();

  const { data: filteredCases } = useProceduresWithQAExperimentResultsOnly({
    pageRadius: 1,
  });
  // 'Boolean(caseId)' is for scenarios that caseId is null (isNaN(null) is false)
  const hasCaseId = Boolean(caseId) && !isNaN(caseId);
  const currentCaseFromSearch = hasCaseId ? find(filteredCases?.procedures, { id: caseId }) : undefined;

  const { queryParams, generateEncodedParams } = useEncodedFilters();

  const [fromStudyFilter] = useQueryParam('fromStudyFilter', StringParam);
  const studyId =
    studyIdFromProps ||
    params?.studyId ||
    currentCaseFromSearch?.studyId ||
    queryParams.filters?.studyId ||
    fromStudyFilter;

  const cancerTypeId = queryParams.filters?.cancerTypeId;

  const { labId } = useCurrentLabId();

  const { data: allSlidesViewerNavigationPrefetchData } = useFilteredSlidesViewerNavigationPrefetchData({
    studyId,
    enabled: Boolean(studyId),
    focusedCaseId: caseId,
    focusedSlideId,
  });

  const fullSlideQueryEncodedFilters = generateEncodedParams({
    filters: { ...(queryParams?.filters || {}), studyId },
  });
  const prefetchedSlideIds = useMemo(() => {
    const idsFromSearchResultsCase = map(currentCaseFromSearch?.slides, 'id');
    if (!isEmpty(idsFromSearchResultsCase)) {
      return idsFromSearchResultsCase;
    }
    const idsFromPrefetchData = map(filter(allSlidesViewerNavigationPrefetchData, { caseId }), 'slideId');
    if (!isEmpty(idsFromPrefetchData)) {
      return idsFromPrefetchData;
    }
    return [focusedSlideId] || [];
  }, [focusedSlideId, currentCaseFromSearch?.slides, allSlidesViewerNavigationPrefetchData]);

  // Should only be needed in a refresh of the page - if we have the case data or the , we don't need to prefetch the slides
  const prefetchedSlideByIdQueries = useQueries({
    queries: map(prefetchedSlideIds, (slideId) => ({
      queryKey: getSlideByIdKeys({
        slideId,
        encodedFilters: fullSlideQueryEncodedFilters,
        skipExtraData: true,
      }),
      queryFn: ({ signal }: { signal?: AbortSignal }) =>
        getSlideById({
          slideId,
          encodedFilters: fullSlideQueryEncodedFilters,
          skipExtraData: true,
          signal,
        }),
      enabled: Boolean(slideId) && enabled && !find(allSlidesViewerNavigationPrefetchData, { slideId }),
    })),
  });

  const prefetchedSlideByIdData = compact(
    map(prefetchedSlideByIdQueries, (query) => (query.isLoading ? null : query.data))
  );

  const caseData: Procedure = useMemo(() => {
    if (!enabled) {
      return undefined;
    }
    const slidesNavigationPrefetchData = filter(allSlidesViewerNavigationPrefetchData, ({ slideId }) =>
      includes(prefetchedSlideIds, slideId)
    );
    const caseLabel = first(slidesNavigationPrefetchData)?.caseLabel;
    return {
      id: caseId,
      label: caseLabel,
      slides: map(
        slidesNavigationPrefetchData,
        ({
          slideId,
          stainTypeId,
          numChannels,
          sizeCols,
          sizeRows,
          maxResolution,
          originalFileName,
          channelMarkerTypes,
          labId: slideLabId,
          slideFormat: format,
        }) => ({
          id: slideId,
          stainingType: stainTypeId,
          format,
          sizeCols,
          sizeRows,
          originalFileName,
          maxResolution,
          channels: numChannels ? fromPairs(times(numChannels, (i) => [i, { id: i, name: `${i}` }])) : undefined,
          channelMarkerTypes: channelMarkerTypes
            ? channelMarkerTypes
            : numChannels
            ? fromPairs(times(numChannels, (i) => [i, '']))
            : undefined,
          labId: slideLabId || labId,
          ...(find(prefetchedSlideByIdData, { id: slideId }) || {}),
        })
      ),
      studyId,
      labId,
      cancerTypeId,
    };
  }, [
    caseId,
    prefetchedSlideIds,
    labId,
    cancerTypeId,
    studyId,
    enabled,
    allSlidesViewerNavigationPrefetchData,
    JSON.stringify(prefetchedSlideByIdData),
  ]);

  return { caseData };
};
