import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Loader from 'components/Loader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { resetCheat, setCheatMode } from 'redux/modules/cheatModeSlice';
import { useAppDispatch } from 'redux/store';
import { StringParam, useQueryParam } from 'use-query-params';
import EmptyPage from '../EmptyPage/EmptyPage';
import StudyCard from './StudyCard';
import StudyFilters from './StudyFilters';
import UploadStudyModal from './UploadStudyModal';

import { Fab } from '@mui/material';
import Grid from '@mui/material/Grid';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteCohort, getCohorts } from 'api/cohorts';
import wrapPage from 'components/atoms/wrapPage/wrapPage';
import AdvancedSearchDrawer from 'components/SearchFilters/AdvancedSearch';
import { CancerType } from 'interfaces/cancerType';
import { Permission } from 'interfaces/permissionOption';
import { filter, find, includes, indexOf, isEmpty, map, values } from 'lodash';
import { useAppSelector } from 'redux/hooks';
import { isDemoLab, isPfizerDemoLab } from 'utils/demo';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { usePermissions } from 'utils/usePermissions';

import { fetchStudies } from 'api/study';
import { Study } from 'interfaces/study';
import { useAllCancerTypes } from 'utils/queryHooks/uiConstantsHooks';
import { useQueryWithErrorAndRetrySnackbar } from 'utils/useQueryWithErrorAndRetrySnackbar';
import DashboardTabs from '../DashboardTabs';
import CohortCard from './CohortCard';

export const SELF_SERVICE = 'self service';
export const PROJECT = 'project';

const StudiesDashboard: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { labId } = useCurrentLabId();
  const dispatch = useAppDispatch();
  const { isLoading: studiesLoading, data: allStudies } = useQueryWithErrorAndRetrySnackbar(
    ['studies', labId, 'withStatistics'],
    fetchStudies(labId, true),
    {
      queriedEntityName: 'studies',
    }
  );
  const { allCancerTypes } = useAllCancerTypes();

  const { all: labs } = useAppSelector((state) => (state as any).labs);
  const cancers: CancerType[] = values(allCancerTypes);

  const { text, startDate, endDate } = useAppSelector((state) => state.studyFilters);
  const { hasPermission } = usePermissions();
  const canApproveCases = hasPermission(Permission.ApproveCases);

  const demoLab = isDemoLab(labId);
  const pfizerDemoLab = isPfizerDemoLab(labId);

  const [isUploadStudyModalOpen, setIsUploadStudyModalOpen] = useState(false);

  const [deletedCohorts, setDeletedCohorts] = useState([]);

  const tabs = ['studies', 'cohorts'];
  const [tab, setTab] = useQueryParam('tab', StringParam);

  const selectedTabIndex = indexOf(tabs, tab) !== -1 ? indexOf(tabs, tab) : 0;

  const queryClient = useQueryClient();

  const { data: cohorts, isLoading: cohortsLoading } = useQuery(['cohorts', labId], () => getCohorts(labId));

  const deleteMutation = useMutation(deleteCohort, {
    onSuccess: () => {
      queryClient.invalidateQueries(['cohorts', labId]);
    },
  });

  const onCohortDelete = (cohortId: string) => {
    setDeletedCohorts([...deletedCohorts, cohortId]);
    deleteMutation.mutate(cohortId);
  };

  const onCheatMode = (event: KeyboardEvent) => {
    if (event.key === '\\') {
      dispatch(
        setCheatMode([
          '7dc4968c-a2db-11ed-9216-675dfa7ed325',
          '31bb4aee-90e6-11ed-b8ce-275bc43751b8',
          '45c3d598-5382-11ed-b233-c787d3c61eb8',
          '6e1ab150-7a16-11ed-afb4-23b1ad3ec431',
          '703130cc-7a16-11ed-afb4-23b1ad3ec431',
          '75c82b6c-7a2f-11ed-b740-ef414576fa6f',
          '5c323dbc-3373-11ed-8f20-6dd71ae56152',
        ])
      );
    }

    if (event.key === 'q' || event.key === 'Q') {
      dispatch(resetCheat());
    }
  };

  useEffect(() => {
    if (demoLab) {
      document.addEventListener('keydown', onCheatMode);

      return () => {
        document.removeEventListener('keydown', onCheatMode);
      };
    }
  }, [demoLab]);

  // eslint-disable-next-line consistent-return
  const filterStudyCard = (study: Study) => {
    if (
      (!text ||
        study.name?.toLocaleLowerCase().includes(text) ||
        study.description?.toLocaleLowerCase().includes(text)) &&
      (!startDate || (study.date && moment(startDate).isBefore(study.date))) &&
      (!endDate || (study.date && moment(endDate).isAfter(study.date)))
    ) {
      return <StudyCard key={study.id} study={study} />;
    }
  };

  const renderStudies = () => {
    if (studiesLoading) {
      return <Loader />;
    } else if (isEmpty(allStudies)) {
      return <EmptyPage page="studies" />;
    } else {
      return map(allStudies, filterStudyCard);
    }
  };

  const changeTab = (newTab: string) => {
    setTab(newTab);
  };

  const snapshotCohorts = filter(cohorts, (cohort) => Boolean(cohort.casesSnapshot));

  return (
    <>
      {isUploadStudyModalOpen && (
        <UploadStudyModal
          isAdmin={canApproveCases}
          labs={labs}
          cancerTypes={cancers}
          onClose={() => setIsUploadStudyModalOpen(false)}
        />
      )}
      <Grid container alignItems="center" justifyContent="space-between" sx={{ my: 4 }}>
        <Grid item>
          <Typography variant="h1">Studies Dashboard</Typography>
        </Grid>
        {!pfizerDemoLab && (
          <Grid item>
            <Tooltip title="Add Study" placement="right-start">
              <Fab color="primary" onClick={() => setIsUploadStudyModalOpen(true)}>
                <AddIcon fontSize="large" />
              </Fab>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {demoLab && (
        <DashboardTabs tabsDisplayTexts={tabs} tabs={tabs} changeTab={changeTab} defaultValueIndex={selectedTabIndex} />
      )}
      {!isEmpty(allStudies) && (
        <Grid container sx={{ my: 4 }} alignItems="center">
          <StudyFilters />
        </Grid>
      )}

      <AdvancedSearchDrawer showApprovedFilter={canApproveCases} targetUrl="/procedures" />
      {tab === 'cohorts' ? (
        <div>
          {cohortsLoading && <Loader />}
          {map(snapshotCohorts, (cohort) => {
            if (cohort && !cohort?.deletedAt)
              return (
                <CohortCard
                  key={cohort.id}
                  cohort={cohort}
                  onDelete={onCohortDelete}
                  isDeleted={includes(deletedCohorts, cohort.id)}
                  study={find(allStudies, (study) => study.id === cohort?.queryObject?.filters['studyId'])}
                />
              );
          })}
        </div>
      ) : (
        <div>{renderStudies()}</div>
      )}
    </>
  );
};

export default wrapPage(StudiesDashboard);
