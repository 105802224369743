import { Box, Grid, Popover, Toolbar } from '@mui/material';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import CasesOverviewDrawer from 'components/CasesOverview/CasesOverviewDrawer';
import AdvancedSearchDrawer from 'components/SearchFilters/AdvancedSearch';
import SiteHeader from 'components/SiteHeader/SiteHeader';
import DashboardFooter from 'components/StudiesDashboard/DashboardFooter';
import { Permission } from 'interfaces/permissionOption';
import { find, flatMap, indexOf, isEmpty, keys, omitBy, pick } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { resetCheat, setCheatStage } from 'redux/modules/cheatModeSlice';
import { BooleanParam, JsonParam, QueryParamConfig, useQueryParam, withDefault } from 'use-query-params';
import { isDemoLab } from 'utils/demo';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { useProceduresWithQAExperimentResultsOnly } from 'utils/useProceduresWithQAExperimentResultsOnly';
import { useQueryWithErrorAndRetrySnackbar } from 'utils/useQueryWithErrorAndRetrySnackbar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import { getCohort } from 'api/cohorts';
import { fetchStudies } from 'api/study';
import { MainPageAnnotationDrawer } from 'components/AssignmentsList/MainPageAnnotationDrawer';
import { TableEditingContextProvider } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { Main } from 'components/atoms/FlexContainers/FlexContainers';
import { RowSelectionContextProvider } from 'components/atoms/RowSelectionContext';
import DashboardTabs from 'components/DashboardTabs';
import EditDrawer from 'components/EditDrawer';
import { Cohort } from 'interfaces/cohort';
import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import CohortDetails from './Cohort/CohortDetails';
import MainContent from './MainStudyDashboardContent';
import StudyDashboardHeader from './StudyDashboardHeader';
import AnnotationAssignmentsView from './Tasks/AnnotationAssignmentsView';

type StudyDashboardTabs = 'Dataset' | 'Tasks';

const StudyDashboardTabParam: QueryParamConfig<StudyDashboardTabs> = withDefault(
  JsonParam,
  'Overview'
) as QueryParamConfig<StudyDashboardTabs>;

const StudyDashboard: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { hasPermission, isLoading } = usePermissions();
  const [advancedSearchDrawerOpen] = useQueryParam('advancedSearchDrawerOpen', BooleanParam);

  const { labId } = useCurrentLabId();

  const canViewAnnotationAssignmentsDashboard = hasPermission(Permission.ViewAnnotationsList);
  const hasAnyOtherTabPermission = canViewAnnotationAssignmentsDashboard;

  const tabs: StudyDashboardTabs[] = useMemo(() => {
    if (!isLoading && !canViewAnnotationAssignmentsDashboard) {
      return ['Dataset'];
    }
    return ['Dataset', 'Tasks'];
  }, [canViewAnnotationAssignmentsDashboard]);
  const [activeTab, setActiveTab] = useQueryParam<StudyDashboardTabs>('tab', StudyDashboardTabParam);
  const activeTabIndex = indexOf(tabs, activeTab as string) !== -1 ? indexOf(tabs, activeTab as string) : 0;

  const { queryParams, setQueryParams } = useEncodedFilters();

  const changeTab = (newTab: StudyDashboardTabs) => {
    setActiveTab(newTab);
  };

  const { data: studies, status: studiesStatus } = useQueryWithErrorAndRetrySnackbar(
    ['studies', labId, 'withStatistics'],
    fetchStudies(labId, true),
    {
      queriedEntityName: 'studies',
    }
  );

  const demoLab = isDemoLab(labId);

  const currentStudy = find(studies, { id: queryParams.filters?.studyId });
  const cohortMode = Boolean(queryParams.cohortId);

  const { data: proceduresData, status: proceduresStatus } = useProceduresWithQAExperimentResultsOnly({
    enabled: Boolean(cohortMode),
    loadIds: true,
  });

  const { data: currentCohort, status: cohortStatus } = useQuery<Cohort>({
    queryKey: ['cohort', queryParams.cohortId],
    queryFn: () => getCohort(queryParams.cohortId),
    enabled: cohortMode,
  });

  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);

  const totalProcedures: number = useMemo(
    () => (cohortMode ? keys(currentCohort?.casesSnapshot)?.length ?? 0 : proceduresData?.totalProcedures ?? 0),
    [cohortMode, currentCohort, proceduresData]
  );

  const { cheatMode, cheatStage } = useAppSelector((state) => state.cheatMode);
  const isCurrentCheatMode = cheatMode.includes(currentStudy?.id);

  const [showCohortDetails, setShowCohortDetails] = useState(false);

  const [, setIsUploadSlidesModalOpen] = useQueryParam('isUploadSlidesModalOpen', BooleanParam);
  const isAnalyzing = Boolean(currentStudy?.analysisStartTime);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowCohortDetails(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setShowCohortDetails(false);
    setAnchorEl(null);
  };

  const applyCohortFilters = () => {
    setQueryParams({
      ...omitBy(
        pick(currentCohort.queryObject, ['filters', 'features', 'clinicalData', 'compositeFilters', 'searchTerm']),
        isEmpty
      ),
      cohortId: null,
      cohortName: null,
      labId,
    });

    setShowCohortDetails(false);
  };

  const insideCohort = cohortMode && Boolean(currentCohort);

  let pageTitle = '';
  if (!insideCohort) {
    pageTitle = currentStudy?.name ? `Study ${currentStudy?.name}` : 'Back to Studies';
  } else {
    pageTitle = currentCohort?.casesSnapshot ? `Cohort ${currentCohort?.name}` : `Query ${currentCohort?.name}`;
  }

  const isAddSlidesDisabled = !currentStudy?.name || isAnalyzing;

  const onCheatMode = (event: KeyboardEvent) => {
    if (event.key === '\\') {
      dispatch(setCheatStage());
    }

    if (event.key === 'q' || event.key === 'Q') {
      dispatch(resetCheat());
    }
  };

  useEffect(() => {
    if (demoLab) {
      document.addEventListener('keydown', onCheatMode);

      return () => {
        document.removeEventListener('keydown', onCheatMode);
      };
    }
  }, [document, demoLab]);

  const specificStudySelected = Boolean(currentStudy);

  const borderColor = theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800];

  const proceduresFieldsContext = useProceduresFieldsContext();

  const slides = flatMap(proceduresData?.procedures, (procedure) => procedure.slides);

  const renderCohortDetailsToggle = () => {
    return currentCohort && currentCohort?.casesSnapshot ? (
      <Grid item sx={{ pl: 0, display: 'flex' }}>
        <Button onClick={handleOpenPopover} color="secondary" size="small">
          {showCohortDetails ? 'Hide Details' : 'Show Details'}
        </Button>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <CohortDetails cohort={currentCohort} applyFunction={applyCohortFilters} />
        </Popover>
      </Grid>
    ) : null;
  };

  const showApprovedFilter = hasPermission(Permission.ApproveCases);

  return (
    <RowSelectionContextProvider>
      <TableEditingContextProvider fieldsContext={proceduresFieldsContext}>
        <StaticPage>
          <SiteHeader />
          <Grid
            container
            direction={'column'}
            sx={{
              height: '100vh',
              width: '100%',
              backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50],
            }}
          >
            <Grid
              item
              container
              sx={{
                width: '100%',
                borderBottom: `1px solid  ${borderColor}`,
              }}
            >
              <Toolbar />
              <StudyDashboardHeader
                study={currentStudy}
                cohortMode={cohortMode}
                renderCohortDetailsToggle={renderCohortDetailsToggle}
                pageTitle={pageTitle}
                tab={queryParams.cohortId ? 'cohorts' : 'studies'}
                isAnalyzing={isAnalyzing}
                isCurrentCheatMode={isCurrentCheatMode}
                cheatStage={cheatStage}
                tooltipText={
                  currentStudy?.name
                    ? isAnalyzing
                      ? 'Upload slides is already done'
                      : 'Upload slides'
                    : 'You need to choose Study'
                }
                isAddSlidesDisabled={isAddSlidesDisabled}
                onAddSlidesClick={() => {
                  setIsUploadSlidesModalOpen(true);
                }}
              />
              {hasAnyOtherTabPermission && (
                <Grid
                  item
                  sx={{
                    px: 8,
                    width: '100%',
                    '.MuiTab-root': {
                      fontSize: 14,
                      textTransform: 'none',
                    },
                  }}
                >
                  <DashboardTabs
                    tabsDisplayTexts={tabs}
                    tabs={tabs}
                    changeTab={changeTab}
                    defaultValueIndex={activeTabIndex}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              item
              container
              xs={true}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                width: '100%',
              }}
            >
              <Grid
                item
                container
                xs={true}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  overflowY: 'auto',
                  width: '100%',
                  py: 2,
                  px: 10,
                }}
              >
                {activeTab === 'Tasks' && canViewAnnotationAssignmentsDashboard ? (
                  <AnnotationAssignmentsView studyId={queryParams.filters?.studyId} />
                ) : (
                  <MainContent studies={studies} />
                )}
              </Grid>
            </Grid>
            <Main leftDrawerOpen={advancedSearchDrawerOpen}>
              <DashboardFooter />
            </Main>
          </Grid>
          {!(activeTab === 'Tasks' && canViewAnnotationAssignmentsDashboard) && (
            <>
              <AdvancedSearchDrawer showApprovedFilter={showApprovedFilter} persistent />
              <CasesOverviewDrawer
                currentStudy={currentStudy}
                totalProcedures={totalProcedures}
                enable={
                  specificStudySelected &&
                  studiesStatus == 'success' &&
                  (cohortMode ? cohortStatus == 'success' : proceduresStatus == 'success')
                }
              />
              {hasPermission(Permission.ViewCasesTable) && (
                <EditDrawer totalItemsToEdit={slidesMode ? slides?.length : proceduresData?.totalProcedures} />
              )}
            </>
          )}
          <MainPageAnnotationDrawer />
        </StaticPage>
      </TableEditingContextProvider>
    </RowSelectionContextProvider>
  );
};

export default StudyDashboard;

const StaticPage = styled(Box)(() => ({
  overflow: 'hidden',
}));
