import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Link } from '@mui/material';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { fetchStudies } from 'api/study';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { filtersDrawerWidth, useStudyDashboardStyles } from 'components/atoms/FlexContainers/FlexContainers';
import { QueryFilter, QueryObject } from 'interfaces/cohort';
import { FieldConfigItem } from 'interfaces/genericFields';
import { Permission } from 'interfaces/permissionOption';
import { cloneDeep, filter, find, join, map } from 'lodash';
import { stringify } from 'qs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import CalculatedFeatures from '../CalculatedFeatures';
import EditCohortsAndQueries from '../EditCohortsAndQueriesDialog';
import useCohortFilters from '../hooks/useCohortFilters';
import useControlledFields, { getUncategorizedFilters } from '../hooks/useControlledFields';
import useSearchFiltersFromQueryParams from '../hooks/useSearchFiltersFromQueryParams';
import MainFilters from '../MainFilters';
import AutocompleteFilter from '../MainFilters/AutocompleteFilter';
import filterFieldsConfig from '../ProcedureFilterFields';
import SaveCohortModal from '../SaveCohortModal';
import { AdvancedSearchFieldGroup } from './AdvancedSearchFieldGroup';
import InferredFeaturesFilters from './InferredFeatures/InferredFeatuersFilters';
import QcFilters from './QcFilters/QcFilters';

interface Props {
  showApprovedFilter: boolean;
  persistent?: boolean;
  targetUrl?: string;
}

const AdvancedSearchDrawer: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  showApprovedFilter,
  persistent = false,
  targetUrl,
}) => {
  const theme = useTheme();
  const { labId } = useCurrentLabId();
  const { queryOptions, snapshotCohortOptions, cohorts, cohortsLoading } = useCohortFilters();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const { mainMarginTop } = useStudyDashboardStyles();

  const { queryParams, setQueryParams } = useSearchFiltersFromQueryParams();
  const { convertToQueryObject } = useEncodedFilters();
  const { data: studies, isLoading: studiesLoading } = useQuery(['studies', labId], fetchStudies(labId));

  const [isSaveCohortModalOpen, setIsSaveCohortModalOpen] = useState(false);
  const [isEditCohortsAndQueriesModalOpen, setIsEditCohortsAndQueriesModalOpen] = useState(false);

  const cohort = find(cohorts, { id: queryParams.cohortId });
  const study = find(studies, { id: queryParams?.filters?.studyId });

  const {
    onFeatureFilterChange,
    onMainFilterChange,
    onFieldChange,
    advancedInputs,
    setAdvancedInputs,
    setFeaturesFilters,
    featuresFilters,
    getFilterObjects,
    createNavQuery,
    ruleBoxes,
    setRuleBoxes,
  } = useControlledFields();

  const handleDrawerToggleButton = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    if (isOpen) {
      setQueryParams({ advancedSearchDrawerOpen: true, labId });
    } else {
      const currentSelectedFeatures = cohort?.queryObject?.features || queryParams.features;

      if (ruleBoxes?.length !== currentSelectedFeatures?.length) {
        const newRuleBoxes = map(currentSelectedFeatures, () => ({}));
        setRuleBoxes(newRuleBoxes);
      }
      setQueryParams({ advancedSearchDrawerOpen: false, labId });
    }
  };

  const [currentQueryObject, setCurrentQueryObject] = useState<Partial<QueryObject>>({});
  const cohortQueryObject = convertToQueryObject(currentQueryObject);

  const onDeleteFeatureFilter = (index: number) => {
    const newFeaturesFilters = cloneDeep(featuresFilters || []);
    newFeaturesFilters.splice(index, 1);
    setFeaturesFilters(newFeaturesFilters);
    setRuleBoxes(filter(ruleBoxes, (r, i) => i !== index));
  };

  const onSubmit = () => {
    const { filters, clinicalData, features, searchTerm } = getFilterObjects();

    if (cohort) {
      setCurrentQueryObject({
        filters: filters as QueryFilter,
        features: features as QueryFilter,
        clinicalData: clinicalData as QueryFilter,
        searchTerm,
      });
      setIsSaveCohortModalOpen(true);
    } else {
      if (!targetUrl) {
        setQueryParams({
          filters,
          clinicalData,
          features,
          labId,
          cohortId: null,
          advancedSearchDrawerOpen: null,
          // Reset pagination
          page: 1,
          slidesMode: queryParams.slidesMode,
          searchTerm,
        });
      } else {
        const navQuery = createNavQuery(filters, clinicalData, features, searchTerm);
        navigate({
          pathname: targetUrl,
          search: `?${stringify(navQuery)}`,
        });
      }
    }
  };

  const onClearForm = () => {
    setAdvancedInputs({
      studyId: 0,
    });
  };

  const borderColor = theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800];
  const canViewInferredFeatures = hasPermission(Permission.EditInferredFeatures); // TODO: find a better permission for this

  const [isCalculatedFeaturesOpen, setIsCalculatedFeaturesOpen] = useState(false);

  const canEditAndDeleteOwnCohorts = hasPermission(Permission.EditAndDeleteOwnCohorts);
  const canEditAndDeleteAllCohorts = hasPermission(Permission.EditAndDeleteAnyCohort);
  const hasAnyEditCohortPermission = canEditAndDeleteOwnCohorts || canEditAndDeleteAllCohorts;

  return (
    <>
      <SaveCohortModal
        isOpen={isSaveCohortModalOpen}
        onClose={() => setIsSaveCohortModalOpen(false)}
        queryObject={cohortQueryObject}
      />
      <EditCohortsAndQueries
        open={isEditCohortsAndQueriesModalOpen}
        onClose={() => setIsEditCohortsAndQueriesModalOpen(false)}
      ></EditCohortsAndQueries>
      <Drawer
        anchor="left"
        open={queryParams.advancedSearchDrawerOpen}
        onClose={handleDrawerToggleButton(false)}
        variant={persistent ? 'persistent' : 'temporary'}
        sx={{
          width: filtersDrawerWidth,
          [`& .MuiDrawer-paper`]: {
            width: filtersDrawerWidth,
            backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50],
            zIndex: persistent ? theme.zIndex.appBar - 1 : 'auto',
            height: persistent ? `calc(100% - ${mainMarginTop}px)` : '100%',
            marginTop: persistent ? `${mainMarginTop}px` : '0px',
          },
        }}
      >
        {queryParams.advancedSearchDrawerOpen && (
          <Grid container direction={'column'} item xs={12} width="100%">
            <Grid
              container
              item
              py={1.5}
              pl={2}
              pr={1}
              alignItems="center"
              sx={{ borderBottom: persistent ? '1px solid ' + borderColor : 'none' }}
            >
              <Grid item container xs={10} alignItems={'center'}>
                <FilterAltIcon />
                <Typography variant="h3">{cohort ? 'Create New' : 'Filter'}</Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <IconButton onClick={handleDrawerToggleButton(false)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={true} sx={{ overflow: 'auto' }} width="100%">
              <Stack style={{ marginTop: '1px' }}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Saved Query and Cohorts</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container direction="column" spacing={2}>
                      <Grid item width="100%">
                        <LabelledDropdown
                          size="small"
                          label="Query"
                          options={queryOptions}
                          value={advancedInputs?.queryId ?? queryParams?.filters?.queryId ?? ''}
                          onOptionSelected={(optionValue) => onMainFilterChange('queryId', optionValue)}
                        />
                      </Grid>
                      <Grid item>
                        <AutocompleteFilter
                          label="Cohort"
                          options={snapshotCohortOptions}
                          limitTags={1}
                          handleChange={(value: string[]) => onMainFilterChange('cohortIds', value)}
                          value={((advancedInputs?.cohortIds || queryParams?.filters?.cohortIds) as string[]) || []}
                          loading={cohortsLoading}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  {hasAnyEditCohortPermission && (
                    <AccordionActions>
                      <Link
                        color="primary"
                        onClick={() => {
                          setIsEditCohortsAndQueriesModalOpen(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        Edit Cohorts And Queries
                      </Link>
                    </AccordionActions>
                  )}
                </Accordion>

                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Main Filters</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item>
                        <MainFilters
                          showApprovedFilter={showApprovedFilter}
                          onChangeFromAdvancedSearch={onMainFilterChange}
                          isDrawer={queryParams.advancedSearchDrawerOpen}
                          controlledValues={getUncategorizedFilters(advancedInputs)}
                          enableQueries={!studiesLoading && !cohortsLoading}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>QC Filters</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <QcFilters study={study} onMainFilterChange={onMainFilterChange} advancedInputs={advancedInputs} />
                  </AccordionDetails>
                </Accordion>

                {canViewInferredFeatures && (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Inferred Features</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InferredFeaturesFilters
                        study={study}
                        onMainFilterChange={onMainFilterChange}
                        advancedInputs={advancedInputs}
                      />
                    </AccordionDetails>
                  </Accordion>
                )}

                <Accordion
                  expanded={isCalculatedFeaturesOpen}
                  onChange={(event, expanded) => setIsCalculatedFeaturesOpen(expanded)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Calculated Features</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CalculatedFeatures
                      study={study}
                      studyIdInput={advancedInputs?.studyId}
                      ruleBoxes={ruleBoxes}
                      onAddRule={() => setRuleBoxes([...ruleBoxes, {}])}
                      featuresFilters={featuresFilters}
                      onFeatureFilterChange={onFeatureFilterChange}
                      onDeleteFeatureFilter={onDeleteFeatureFilter}
                      // Not waiting for isCalculatedFeaturesOpen to preload the features, as it takes time.
                      // Web worker will ensure that the features are loaded in the background.
                      enabled={queryParams.advancedSearchDrawerOpen && !studiesLoading}
                    />
                  </AccordionDetails>
                </Accordion>
                {map(filterFieldsConfig, (section: FieldConfigItem, sectionKey: number) => (
                  <Accordion key={sectionKey}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>{section.title}</AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {map(section.fieldGroups, (fieldGroup, fieldGroupKey) => (
                          <AdvancedSearchFieldGroup
                            advancedInputs={advancedInputs}
                            // Use the fieldGroupKey and the values of the fields to create a unique key that will clear the fields when reset
                            key={`${fieldGroupKey}-${join(map(fieldGroup.fields, 'dataKey'), '-')}`}
                            fieldGroup={fieldGroup}
                            isLast={fieldGroupKey !== section.fieldGroups?.length - 1}
                            onFieldChange={onFieldChange}
                          />
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Stack>
            </Grid>
            <Grid item>
              <Grid container justifyContent="space-around" alignItems="center" py={1.5}>
                <Grid item>
                  <Button variant="text" color="secondary" onClick={onClearForm}>
                    Clear
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" aria-label="submit" onClick={onSubmit}>
                    {cohort ? 'Save As...' : 'Apply Filters'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Drawer>
    </>
  );
};

export default AdvancedSearchDrawer;
