import { Job, JobResponse, JobStatus, JobType } from 'interfaces/job';
import { stringify } from 'qs';
import { BooleanParam, JsonParam, NumberParam, StringParam, encodeQueryParams } from 'use-query-params';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export interface JobFilters {
  studyId: string;
  jobName?: string;
  orchestrationId?: string;
  users?: Record<string, string>[];
  senders?: string[];
  slideIds?: string[];
  statuses?: JobStatus[];
  type?: JobType;
}

export interface GetJobsParams {
  filters: JobFilters;
  labId: string;
  page?: number;
  pageSize?: number;
  fullData?: boolean;
  childTasksOnly?: boolean;
}

export const getJobs = (params: GetJobsParams, signal?: AbortSignal) => {
  const stringParams = stringify(
    encodeQueryParams({ filters: JsonParam, labId: StringParam, page: NumberParam, fullData: BooleanParam }, params)
  );

  return apiRequestHandlerPromise<JobResponse>({
    url: `jobs?${stringParams}`,
    method: 'GET',
    signal,
  });
};

export const getJob = (jobId: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<Job>({
    url: `jobs/${jobId}`,
    method: 'GET',
    signal,
  });
};

export const cancelJob = (jobId: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<Job>({
    url: `jobs/${jobId}/cancel`,
    method: 'POST',
    signal,
  });
};
