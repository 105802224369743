import { Signal, signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { cloneDeep, Dictionary, filter, first, forEach, isEmpty, isEqual, keys, pick, times, values } from 'lodash';
import { useCallback, useTransition } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { useDebouncedCallback } from 'use-debounce';

export interface BaseSlideVisualSettings {
  show: boolean;
  opacity: number;
}

export interface LayerVisualizationSettings {
  show: boolean;
  opacity: number;
  selected?: boolean;
  color?: any;
}

// { [slideId]: BaseSlideVisualSettings }
export const baseSlidesVisualSettings = times(MAX_VIEWERS, () => signal<Dictionary<BaseSlideVisualSettings>>({}));

// { [slideId]: { [layerId]: Signal<LayerVisualizationSettings> } }
export const slidesLayerVisualizationSettings = times(MAX_VIEWERS, () =>
  signal<Dictionary<Dictionary<Signal<LayerVisualizationSettings>>>>({})
);

export const defaultSlideOpacity = 100;
export const defaultHeatmapOpacity = 50;

export const defaultBaseSlideVisualSettings: BaseSlideVisualSettings = { show: true, opacity: defaultSlideOpacity };
export const defaultLayerVisualizationSettings: LayerVisualizationSettings = {
  show: false,
  opacity: defaultHeatmapOpacity,
  selected: false,
};

// We store heatmap settings by stain and layer key (generally the heatmap's displayName) to allow us to apply settings to the same heatmap across different slides.
// For a given layer, we will prefer settings for a matching stain or viewer, but still defer to any settings for the layer.
export const useUrlLayerSettingsPerStainAndDisplayName = () =>
  useQueryParam<{
    [layerUrlKey: string]: { [stainTypeId: string]: { [viewerIndex: number]: LayerVisualizationSettings } };
  }>('layerSettingsPerStainAndDisplayName', JsonParam);

export const useGetLayerSettingsFromUrl = () => {
  const [urlLayerSettingsPerStainAndViewer] = useUrlLayerSettingsPerStainAndDisplayName();

  return useCallback(
    ({ layerUrlKey, stainTypeId, viewerIndex }: { layerUrlKey: string; stainTypeId: string; viewerIndex: number }) => {
      const layerUrlSettingsDictionary = urlLayerSettingsPerStainAndViewer?.[layerUrlKey];

      // Prefer settings for the stain and viewer
      const matchByStainAndViewer = layerUrlSettingsDictionary?.[stainTypeId]?.[viewerIndex];
      if (matchByStainAndViewer) {
        return matchByStainAndViewer;
      }
      // Fallback to settings for the stain, regardless of viewer
      const matchByStain = first(values(layerUrlSettingsDictionary?.[stainTypeId]));
      return matchByStain;
    },
    [urlLayerSettingsPerStainAndViewer]
  );
};

export const useApplyChangesToSlideLayerVisualizationSettings = () => {
  useSignals();

  const [urlLayerSettingsPerStainAndViewer, setUrlLayerSettingsPerStainAndDisplayName] =
    useUrlLayerSettingsPerStainAndDisplayName();

  const setUrlLayerSettingsPerStainAndDisplayNameDebounced = useDebouncedCallback(
    setUrlLayerSettingsPerStainAndDisplayName,
    500
  );

  const [, startTransition] = useTransition();

  const applyChangesToSlideLayerVisualizationSettings = ({
    slideId,
    viewerIndex,
    changes,
    skipUrlUpdate,
    stainTypeId,
    debug,
  }: {
    slideId: string;
    viewerIndex: number;
    changes: Array<{
      layerId: string;
      newSettings: Partial<LayerVisualizationSettings>;
      // This is the key for the layer settings in the URL, in case layerId is specific to a slide
      // This allows us to apply settings to the same layer across different slides (i.e. when navigating between slides)
      layerUrlKey?: string;
    }>;
    skipUrlUpdate?: boolean;
    stainTypeId?: string; // If passed, we will update the URL with the new settings
    debug?: boolean;
  }) => {
    if (isEmpty(changes)) {
      if (debug) {
        console.debug('No changes to apply');
      }
      return;
    }
    if (debug) {
      console.debug('Applying changes to slide layer visualization settings', {
        slideId,
        viewerIndex,
        changes,
        skipUrlUpdate,
        stainTypeId,
      });
    }
    if (!slidesLayerVisualizationSettings[viewerIndex]?.value) {
      console.error(`Invalid viewerIndex: ${viewerIndex}`);
      return false;
    }
    if (!slidesLayerVisualizationSettings[viewerIndex].value[slideId]) {
      if (debug) {
        console.debug(`Creating new settings for slideId: ${slideId}`);
      }
      slidesLayerVisualizationSettings[viewerIndex].value = {
        ...slidesLayerVisualizationSettings[viewerIndex].value,
        [slideId]: {},
      };
    }
    const relevantChanges = filter(changes, ({ layerId, newSettings }) => {
      if (!slidesLayerVisualizationSettings[viewerIndex].value[slideId][layerId]) {
        if (debug) {
          console.debug(`Creating new settings for layerId: ${layerId}`);
        }
        slidesLayerVisualizationSettings[viewerIndex].value = {
          ...slidesLayerVisualizationSettings[viewerIndex].value,
          [slideId]: {
            ...slidesLayerVisualizationSettings[viewerIndex].value[slideId],
            [layerId]: signal<LayerVisualizationSettings>({
              ...defaultLayerVisualizationSettings,
            }),
          },
        };
      }
      const heatmapSettings = slidesLayerVisualizationSettings[viewerIndex].value[slideId][layerId];
      const currentHeatmapSettings = heatmapSettings.value;

      const isRelevantChange = !isEqual(newSettings, pick(currentHeatmapSettings, keys(newSettings)));
      if (debug) {
        console.debug(`isRelevantChange: ${isRelevantChange}`, {
          layerId,
          newSettings,
          currentHeatmapSettings,
        });
      }
      return isRelevantChange;
    });

    if (isEmpty(relevantChanges)) {
      if (debug) {
        console.debug('No relevant changes to apply');
      }
      return;
    }

    const slideViewerSettings = slidesLayerVisualizationSettings[viewerIndex].value[slideId];
    forEach(relevantChanges, ({ layerId, newSettings }) => {
      const heatmapSettings = slideViewerSettings?.[layerId];
      if (!heatmapSettings) {
        console.error(`Failed to find heatmap settings for layerId: ${layerId}`, {
          slideId,
          viewerIndex,
          currentSettings: cloneDeep(slideViewerSettings || {}),
        });
        return;
      }
      const currentHeatmapSettings = heatmapSettings.value;

      heatmapSettings.value = {
        ...defaultLayerVisualizationSettings,
        ...currentHeatmapSettings,
        ...newSettings,
      };

      if (debug) {
        console.debug('Applying changes to layer visualization settings', {
          slideId,
          viewerIndex,
          layerId,
          newSettings,
          currentHeatmapSettings,
        });
      }
    });

    if (!skipUrlUpdate && stainTypeId) {
      startTransition(() => {
        const newUrlValue = cloneDeep(urlLayerSettingsPerStainAndViewer || {});

        forEach(relevantChanges, ({ layerId, layerUrlKey = layerId, newSettings }) => {
          const heatmapSettings = slidesLayerVisualizationSettings[viewerIndex]?.value?.[slideId]?.[layerId];
          if (!heatmapSettings) {
            console.error(`Failed to find heatmap settings for layerId: ${layerId}`, {
              slideId,
              viewerIndex,
              currentSettings: cloneDeep(slideViewerSettings || {}),
            });
            return;
          }
          const previousUrlLayerSettings = urlLayerSettingsPerStainAndViewer?.[layerUrlKey] || {};
          const previousUrlStainSettings = previousUrlLayerSettings?.[stainTypeId] || {};
          const currentHeatmapSettings = heatmapSettings.value;

          const newUrlLayerSettingsForViewer = {
            ...currentHeatmapSettings,
            ...(previousUrlStainSettings?.[viewerIndex] || {}),
            ...newSettings,
          };
          newUrlValue[layerUrlKey] = {
            ...previousUrlLayerSettings,
            [stainTypeId]: {
              ...previousUrlStainSettings,
              [viewerIndex]: newUrlLayerSettingsForViewer,
            },
          };
        });
        setUrlLayerSettingsPerStainAndDisplayNameDebounced(newUrlValue, 'replaceIn');
      });
    } else if (!skipUrlUpdate && !stainTypeId) {
      console.warn('stainTypeId is required for URL updates', { slideId, viewerIndex, stainTypeId });
    } else if (skipUrlUpdate && stainTypeId) {
      console.warn('stainTypeId is not necessary when skipping URL updates is not provided', {
        slideId,
        viewerIndex,
        stainTypeId,
      });
    }
  };

  return applyChangesToSlideLayerVisualizationSettings;
};
