import { Box, List } from '@mui/material';
import { map } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import { AnnotationExtensionStates } from 'interfaces/annotation';
import { useActiveAnnotationForViewer } from 'services/annotations/useAnnotationQueryParams';
import AssignmentsListHeader from './AssignmentsListHeader';
import AssignmentsListItem from './AssignmentsListItem';
import AssignmentsSearchBox from './AssignmentsSearchBox';
import { AssignmentFilterResults, useFilteredAssignments } from './filterAssignments';
import { ASSIGNMENT_STATES } from './state';

interface Props {
  pinned?: boolean;
  enabled?: boolean;
  togglePinned?: () => void;
}

const AssignmentList: React.FunctionComponent<Props> = ({ pinned, togglePinned, enabled = true }) => {
  const params = useParams();
  const activeSlideId: string = params.slideId;

  // 0 is for the first viewer
  const [activeAnnotationAssignmentId] = useActiveAnnotationForViewer(0);

  const [expandedAssignments, setExpandedAssignments] = useState<{ [assignmentId: string]: boolean }>(
    !isNaN(activeAnnotationAssignmentId) ? { [activeAnnotationAssignmentId]: true } : {}
  );

  const [currentFilter, setCurrentFilter] = useState('');

  const [annotationFilterState, setAnnotationFilterState] = useQueryParam('annotationFilterState', StringParam);
  const onSelectState = (assignmentState: AnnotationExtensionStates) => {
    setAnnotationFilterState(ASSIGNMENT_STATES[assignmentState].route);
  };

  const { filteredAssignments, isLoadingAssignments, isLoadingExtensions } = useFilteredAssignments({
    annotationFilterState,
    currentFilter,
    enableQueries: enabled,
  });

  const isExpanded = ({ assignment, doesMatchState }: AssignmentFilterResults): boolean => {
    const expandedAssignment = expandedAssignments[assignment?.annotationAssignmentId];
    if (currentFilter) {
      if (doesMatchState) {
        return Boolean(expandedAssignment);
      } else if (expandedAssignment === undefined || expandedAssignment) {
        return true;
      }
    }

    return Boolean(expandedAssignment);
  };

  // Scroll to active slide when opening the drawer
  const activeSlideRef = useRef<HTMLLIElement>();
  useEffect(() => {
    if (activeSlideRef.current) {
      activeSlideRef.current.scrollIntoView({ block: 'center' });
    }
  }, [isLoadingAssignments, isLoadingExtensions]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <AssignmentsListHeader
        state={annotationFilterState}
        onSelectState={onSelectState}
        togglePinned={togglePinned}
        pinned={pinned}
        isLoading={isLoadingAssignments || isLoadingExtensions}
      />
      <AssignmentsSearchBox
        setFilter={(newFilter) => {
          setCurrentFilter(newFilter);
          onSelectState(AnnotationExtensionStates.AllAssignment);
        }}
      />
      <List sx={{ overflowY: 'scroll' }}>
        {map(filteredAssignments, ({ assignment, doesMatchState, slides }) => (
          <AssignmentsListItem
            ref={activeSlideRef}
            key={assignment.annotationAssignmentId}
            currentFilter={currentFilter}
            annotationAssignmentId={assignment.annotationAssignmentId}
            doesMatchState={doesMatchState}
            assignment={assignment}
            slides={slides}
            activeSlide={activeSlideId}
            expanded={isExpanded({ assignment, doesMatchState })}
            setExpandedAssignments={setExpandedAssignments}
          />
        ))}
      </List>
    </Box>
  );
};

export default AssignmentList;
